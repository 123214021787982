import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZKpi = z.object(
  {
    kpi_definition_id: z.string(),
    kpi_value: z.number(),
    timestamp: z.string()
  })

export type DailyKpi = z.infer<typeof ZKpi>

export async function getDataCompletenessKpis (
  tenantId: string,
  onSuccess: (dailyKpis: DailyKpi[], httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  // Move the KPIList out soon
  const UUIDList = 'kpiList=17d3138f-e42a-411a-95e9-4f64e2296c25,3c653f68-633b-406b-9f39-a9007bee27c6,ad981ad4-b0f2-47ff-8b79-7774a6f2a209'
  const url = '/kpirest/api/v1/udp/kpis/latest?' + UUIDList + `&tenantId=${tenantId}`
  interceptedAxios.get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZKpi.array().safeParse(response.data.data)
        if (parseResponse.success) {
          const parsedData: DailyKpi[] = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch(
      (error) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const statusCode = error.response ? error.response.status : 500
        onFailure(error.message, statusCode)
      })
}
