import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZParams = z.object({
  group_id: z.string(),
  group_name: z.string(),
  status: z.string()
})

const ZConfiguration = z.object({
  groups: z.array(ZParams),
  template_id: z.string(),
  template_name: z.string(),
  asset_type_id: z.string(),
  category_type_id: z.string(),
  asset_type_name: z.string(),
  category_type_name: z.string(),
  status: z.string(),
  assets_associated: z.number()
})

const ZConfigurationTemplate = z.object({
  total_count: z.number(),
  configuration_templates: z.array(ZConfiguration)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZConfigurationTemplate.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type Configuration = z.infer<typeof ZConfigurationTemplate>
export type ConfigurationObj = z.infer<typeof ZConfiguration>
export type GroupParaObj = z.infer<typeof ZParams>

export async function getConfigurationList (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  tenantId: string,
  onSuccess: (configurationList: Configuration, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/commands/configuration/templates?tenantId=${tenantId}&page=${page}&size=${size}&sortdir=${sortdir}&sortfield=${sortfield}`

  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.error_message, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore next
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
