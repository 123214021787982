import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryDonutTableDataObject } from '../types'

export interface HistoryDonutTableState {
  isLoading: boolean
  errorMessage: string | undefined
  data: HistoryDonutTableDataObject | undefined
  httpStatus: number
}

const initialState: HistoryDonutTableState = {
  isLoading: true,
  errorMessage: undefined,
  data: undefined,
  httpStatus: 0
}

const historyDonutTableSlice = createSlice({
  name: 'KpiHistoryDonutTable',
  initialState,
  reducers: {
    historyDonutTableLoadingAction: (state) => {
      return {
        isLoading: true,
        errorMessage: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    historyDonutTableUpdateAction: (state, action: PayloadAction<{ data: HistoryDonutTableDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    historyDonutTableErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  historyDonutTableLoadingAction,
  historyDonutTableUpdateAction,
  historyDonutTableErrorAction
} = historyDonutTableSlice.actions

export default historyDonutTableSlice.reducer
