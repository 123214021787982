import React, { useEffect } from 'react'
import DarkUnica from 'highcharts/themes/dark-unica'
import * as HighCharts from 'highcharts'
import { Card, CardContent, CircularProgress, Grid } from '@mui/material'
import { useBarGraphDim } from '../../../../customHooks/barGraphDim'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import deleteIcon from '../../../../assets/deleteIcon.svg'
import { type KpiKeyDonut, type KpiKey } from '../../redux/changeKpiType'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isClickableKpiKey } from '../../redux/changeKpiType'
import { type DonutKpiState } from '../../types'
import { getReactApexDonutChart, type DonutSeriesData } from './DonutChart'
import { kpiColorMapDonut } from './BuildKpiData'
import { getKpiInfo } from './kpiInfo'
import { getReactApexPieChart } from './PieChart'
import { usePieChartDim } from '../../../../customHooks/pieChartDim'

interface Props {
  isHistory: boolean
  kpiName: KpiKeyDonut
  buildDonutChartData: (kpiName: KpiKeyDonut, kpiData: DonutSeriesData, setKpiData: React.Dispatch<React.SetStateAction<DonutSeriesData>>, kpiDataState: DonutKpiState) => void
  state: DonutKpiState
  handleKpiRemove: (kpi: KpiKey) => void
  retryHandler: () => void
}

export function getShortenedName (name: string): JSX.Element {
  let modifiedName = name
  if (modifiedName.length > 25) {
    modifiedName = modifiedName.substring(0, 25) + '...'
    return <abbr title={name} style={{ textDecoration: 'none' }}>{modifiedName}</abbr>
  } else {
    return <span>{modifiedName}</span>
  }
}

function DonutKPI (props: Props): JSX.Element {
  DarkUnica(HighCharts)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [height, centerFont] = useBarGraphDim()
  const [pieHeight] = usePieChartDim()
  const [count, setCount] = React.useState<number>(0)

  const kpiClickHandler = (kpiName: KpiKey): void => {
    if (!props.isHistory && isClickableKpiKey(kpiName)) {
      navigate('/kpi-details')
      dispatch({ type: 'kpiTypeSlice/changeKpiType', payload: kpiName })
    }
  }

  const kpiDataTemp: DonutSeriesData = {
    name: [],
    data: [],
    color: []
  }

  const [kpiData, setKpiData] = React.useState<DonutSeriesData>(kpiDataTemp)

  useEffect(() => {
    props.buildDonutChartData(props.kpiName, kpiData, setKpiData, props.state)
    if (props.state.data !== undefined) {
      setCount(props.state.data.totalCount)
    }
  }, [props.state])

  const handleWithFailure = (): JSX.Element => {
    if (props.state.isLoading) {
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>
      )
    } else if (props.state.httpStatus === 401) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.state.httpStatus === 403) {
      return (<div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="accessForbidden"/></div>)
    } else if (props.state.httpStatus === 200 && props.state.data === undefined) {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (props.state.data !== undefined && props.state.httpStatus === 200) {
      return (
        <>
          <div>
            {kpiData.name.length <= 4 ? getReactApexDonutChart(kpiData, height, centerFont) : getReactApexPieChart(kpiData, pieHeight)}
          </div>
          <table>
            <tbody className={props.kpiName === 'Firmware Breakdown' ? 'kpi-legends-responsive' : ''}>
              {props.state.data.liveData[0].dataPoint.map((data, index) => (
                <tr key={index}>
                  <td style={{ paddingRight: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ backgroundColor: kpiColorMapDonut[props.kpiName][index], width: 4, height: 16 }}></span>
                      <span className='responsive-legends-LD' style={{ marginLeft: 10, whiteSpace: data.name.toUpperCase().includes('ECL') ? 'nowrap' : 'initial' }}>{getShortenedName(data.name)}</span>
                    </div>
                  </td>
                  <td className='responsive-legends-LD' style={{ paddingLeft: '10px' }}>
                  <span style={{ whiteSpace: 'nowrap' }}>≅ {data.percentage.toFixed(2)}%</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )
    } else {
      return (
        <div className='authErrorThirtyDaysTend daystrend401'><AuthError errorMessage="cannotFetch" retry={props.retryHandler}/></div>
      )
    }
  }

  return (
    <>
      <Card className='kpi-card' sx={{ height: window.innerWidth >= 1896 ? 457 : 382, borderRadius: '10px', backgroundColor: '#313030', color: '#f0f0f0' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div onClick={() => { kpiClickHandler(props.kpiName) }} style={{ cursor: 'pointer', fontSize: window.innerWidth > 1300 ? '16px' : '13px', padding: '15px', letterSpacing: '1px', textTransform: 'uppercase', color: isClickableKpiKey(props.kpiName) ? '#64C3FF' : '#F0F0F0' }}>{props.kpiName} ({count}) {getKpiInfo(props.kpiName)}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src={deleteIcon} onClick={() => { props.handleKpiRemove(props.kpiName) }} style={{ marginRight: 15, cursor: 'pointer', marginLeft: 5 }} alt='delete'/>
          </div>
        </div>
        <CardContent>
          <Grid>
            <div id='data-comp-container' className='centerChart'>
              <div className='circleChartDataComp' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                  handleWithFailure()
                }
              </div>
            </div>
          </Grid>
        </CardContent>
        {/* <p style={{ position: 'absolute', left: 40, bottom: 40 }}>*Total percentage <span>≅</span> 100</p> */}
      </Card>
    </>
  )
}

export default DonutKPI
