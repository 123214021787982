import * as React from 'react'
import { CircularProgress } from '@mui/material'
import Pagination from '../../../../components/TablePagination'
import { type SnackbarOrigin } from '@mui/material/Snackbar'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import {
  fetchAllActiveAlarms
} from '../../redux/actionCreators'
import { type RootState } from '../../../../store'
import { type ConnectedProps, connect } from 'react-redux'
import LanguageStrings from '../../../../i18n/locales'
import { useNavigate } from 'react-router-dom'
import { changeAssetId } from '../../../device-details/redux/assetIdSlice'
import { useDispatch } from 'react-redux'
import { type AlarmDataState } from '../../redux/allAlarmsSlice'
import lowBadge from '../../../../assets/lowBadge.svg'
import highBadge from '../../../../assets/highBadge.svg'
import mediumBadge from '../../../../assets/mediumBadge.svg'
import CriticalBadge from '../../../../assets/criticalBadge.svg'
import { changeActiveIssuesCount } from '../../redux/activeIssuesCountSlice'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { useAuth } from 'react-oidc-context'
import { visuallyHidden } from '@mui/utils'

export interface State extends SnackbarOrigin {
  open: boolean
}

interface Data {
  name: string
  serial_number: string
  latitude: number
  longitude: number
  install_date: Date | null
  removal_date: Date | null
}

const style = {
  width: '100%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

type Order = 'asc' | 'desc'
interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

// const TableStrings = LanguageStrings().AMIDataCollectionStrings.TableComponent
const OverviewStrings = LanguageStrings().DeviceDetailsStrings.Overview
const headCells: readonly HeadCell[] = [
  {
    id: 'assetName',
    numeric: false,
    disablePadding: false,
    label: OverviewStrings.ASSET_NAME
  },
  {
    id: 'alarmName',
    numeric: false,
    disablePadding: false,
    label: OverviewStrings.ALARM
  },
  {
    id: 'alarmStatus',
    numeric: false,
    disablePadding: false,
    label: OverviewStrings.STATUS
  },
  {
    id: 'readingTime',
    numeric: false,
    disablePadding: false,
    label: OverviewStrings.READING_TIME
  },
  {
    id: 'severity',
    numeric: false,
    disablePadding: false,
    label: OverviewStrings.SEVERITY
  }
]

function EnhancedTableHead (props: any): any {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
      // istanbul ignore next
      onRequestSort(event, newOrderBy)
    }
  return (
    <TableHead data-testid="table-header">
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <StyledTableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
          >
            <TableSortLabel
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                aria-label={headCell.label}
                data-testid="test-sort"
              >
                {headCell.label}
                {orderBy === headCell.id
                  ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                    )
                  : null}
              </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface Sort {
  order: Order
  orderBy: string
}

interface alarmsData {
  alarmDefinitionId: string
  assetId: string
  assetName: string
  alarmName: string
  severity: string
  alarmStatus: string
  readingTime: string
}

const getStatusIcons = (status: string): JSX.Element => {
  switch (status.toLowerCase()) {
    case 'low':
      return <img src={lowBadge} />
    case 'medium':
      return <img src={mediumBadge} />
    case 'high':
      return <img src={highBadge} />
    case 'critical':
      return <img src={CriticalBadge} />
    default:
      return <img src={lowBadge} />
  }
}

function ActiveAlarmsList (props: PropsFromRedux): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const rowsPerPage = 10
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'assetName' })
  const [alarms, setAlarms] = React.useState<alarmsData[] | []>([])
  const auth = useAuth()

  const fetchIssues = (page: number): void => {
    // istanbul ignore else
    if (page >= 1) {
      props.fetchAllActiveIssues(
        page - 1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        auth.user?.profile.customer as string
      )
    }
  }

  React.useEffect(() => {
    fetchIssues(page)
  }, [sort, page])

  React.useEffect(() => {
    const cleanup = (): void => {
      setAlarms([])
    }
    props.allActiveIssues.alarmData?.forEach((alarm: any) => {
      setAlarms((prevAlarms) => [...prevAlarms, alarm])
    })
    dispatch(
      changeActiveIssuesCount(
        props.allActiveIssues.count !== undefined
          ? props.allActiveIssues.count
          : 0
      )
    )

    return cleanup
  }, [props.allActiveIssues.alarmData])

  function parseDate (date: string): string {
    return new Date(date).toUTCString()
  }

  // istanbul ignore next
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ): void => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }

  const handleRetry = (): void => {
    props.fetchAllActiveIssues(page - 1, rowsPerPage, sort.order, sort.orderBy, auth.user?.profile.customer as string)
  }

  // istanbul ignore next
  const handleDeviceDetailClick = (
    assetId: string,
    assetName: string
  ): void => {
    dispatch(changeAssetId(assetId))
    sessionStorage.setItem(
      'paramAssetId',
      assetId != null ? assetId.toString() : ''
    )
    sessionStorage.setItem(
      'paramAssetName',
      assetName != null ? assetName.toString() : ''
    )
    navigate('/devicedetails', { state: { previousPath: window.location.pathname } })
  }

  const StyledTableRow = styled(TableRow)(() => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))

  if (
    props.allActiveIssues.alarmData !== null &&
    props.allActiveIssues.alarmData !== undefined && props.allActiveIssues.alarmData.length > 0
  ) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table">
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                 <EnhancedTableHead
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {alarms.length > 0 &&
                    alarms.map((row: alarmsData, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={`${row.assetId}-${row.alarmDefinitionId}`}
                          aria-label="devices-list-row"
                        >
                          <StyledTableCell onClick={() => {
                            handleDeviceDetailClick(row.assetId, row.assetName)
                          }} sx={{ padding: '1.2px 16px', color: '#64C3FF !important', cursor: 'pointer' }}>{row.assetName}</StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            aria-label="device-name"
                            data-testid="device-name"
                          >
                            {row.alarmName}
                          </StyledTableCell>
                          <StyledTableCell sx={{ padding: '1.2px 16px' }}>
                            {row.alarmStatus.toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell sx={{ padding: '1.2px 16px' }}>
                            {parseDate(row.readingTime)}
                          </StyledTableCell>
                          <StyledTableCell sx={{ padding: '1.2px 16px' }}>
                            {getStatusIcons(row.severity)}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={props.allActiveIssues.count}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              siblingCount={1}
              boundaryCount={1}
            />
          </Paper>
        </Box>
      </>
    )
  } else if (props.allActiveIssues.isLoading) {
    return (
      <div style={{ width: '147vw', display: 'flex', justifyContent: 'center' }} className="CircularprogressAmi">
        <CircularProgress />
      </div>
    )
  } else if (
    (props.allActiveIssues.alarmData !== null &&
    props.allActiveIssues.alarmData !== undefined &&
    props.allActiveIssues.alarmData.length === 0) || (props.allActiveIssues.error === 'No alarms found' && props.allActiveIssues.httpStatus === 404)
  ) {
    return (
      <div className="authError">
        <AuthError errorMessage="NoDataPresent" />
      </div>
    )
  } else if (props.allActiveIssues.httpStatus === 401) {
    return (
      <div className="authError">
        <AuthError errorMessage="Unauthorized" />
      </div>
    )
  } else if (props.allActiveIssues.httpStatus === 403) {
    return (
      <div className="authError">
        <AuthError errorMessage="accessForbidden" />
      </div>
    )
  } else {
    return (
      <div className="authError" style={{ margin: '1em' }}>
        <AuthError errorMessage="cannotFetch" retry={handleRetry} />
      </div>
    )
  }
}
interface DispatchToProps {
  fetchAllActiveIssues: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    tenantId: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAllActiveIssues: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    tenantId: string
  ) => dispatch(fetchAllActiveAlarms(page, size, sortdir, sortfield, tenantId))
})

interface StateToProps {
  allActiveIssues: AlarmDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  allActiveIssues: state.allActiveIssues
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ActiveAlarmsList)
