import { type AppDispatch } from '../../../store'
import * as AssetGroupListService from '../services/assetGroupListService'
import * as CreateGroupService from '../services/createGroupService'
import * as AssetForGroupService from '../services/assetListForGroupService'
import * as AssetGroupListByFilterParaService from '../services/getGroupListByFilterParameter'
import { createGroupErrorAction, createGroupLoadingAction, createGroupUpdateAction } from './createGroupSlice'
import { type AssetForGroup, type CreateGroupPostRequest, type AssetGroupDetails, type GroupsAsset, type UpdateGroupRequest } from '../types'
import * as UpdateGroupService from '../services/updateGroupService'
import { type CreateGroupApiResponse } from '../services/createGroupService'
import { assetsForCreateGroupErrorAction, assetsForCreateGroupLoadingAction, assetsForCreateGroupUpdateAction } from './assetForCreateGroupSlice'
import * as AssetGroupDetailsService from '../services/assetGroupDetailsService'
import * as exportGroupDetailsService from '../services/exportGroupDetailsService'
import { assetGroupsListErrorAction, assetGroupsListLoadingAction, assetGroupsListUpdateAction } from './AssetGroupsListSlice'
import { assetGroupDetailsErrorAction, assetGroupDetailsLoadingAction, assetGroupDetailsUpdateAction } from './AssetGroupDetailsSlice'
import { assetAssignedListErrorAction, assetAssignedListLoadingAction, assetAssignedListUpdateAction } from './AssetAssignedListSlice'
import { exportGroupDetailsByIdLoadingAction, exportGroupDetailsByIdUpdateAction, exportGroupDetailsByIdErrorAction } from './exportGroupDetailsSlice'
import { updateGroupErrorAction, updateGroupLoadingAction, updateGroupUpdateAction } from './updateGroupSlice'
import { type UpdateGroupApiResponse } from '../services/updateGroupService'
import { unassignedAssetListErrorAction, unassignedAssetListLoadingAction, unassignedAssetListUpdateAction } from './UnassignedAssetListSlice'
import * as getAllGroupFilterParameterService from '../services/getAllGroupFilterParameter'
import { getAllGroupFilterParameterDataLoading, getAllGroupFilterParameterDataError, getAllGroupFilterParameterData } from '../redux/getAllGroupFilterParameterSlice'
import { deleteGroupErrorAction, deleteGroupLoadingAction, deleteGroupUpdateAction } from './DeleteGroupSlice'
import * as DeleteGroupService from '../services/deleteGroupService'

export const fetchAssetGroupList = (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  input: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetGroupsListLoadingAction())

  void AssetGroupListService.getAssetGroupList(
    page, size, sortdir, sortfield, input,
    (assetGroupList: AssetGroupListService.AssetGroupObj, httpStatus: number) => {
      dispatch(
        assetGroupsListUpdateAction({
          data: assetGroupList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetGroupsListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const getAssetGroupListByFilterParamterInfo = (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  input: string,
  filterParams: any
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetGroupsListLoadingAction())

  void AssetGroupListByFilterParaService.getAssetGroupList(
    page, size, sortdir, sortfield, input, filterParams,
    (assetGroupList: AssetGroupListByFilterParaService.AssetGroupObj, httpStatus: number) => {
      dispatch(
        assetGroupsListUpdateAction({
          data: assetGroupList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetGroupsListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const getAllGroupFilterParameterInfo = () => async (dispatch: any) => {
  dispatch(getAllGroupFilterParameterDataLoading())
  void getAllGroupFilterParameterService.getAllGroupFilterParameter((data: any, httpStatus: number) => {
    dispatch(getAllGroupFilterParameterData({ data, httpStatus }))
  },
  (errorMessage: string, httpStatus: number) => {
    dispatch(getAllGroupFilterParameterDataError({ errorMessage, httpStatus }))
  })
}

export const exportGroupDetailsById = (assetGroupId: string) => async (dispatch: any) => {
  dispatch(exportGroupDetailsByIdLoadingAction())
  try {
    await exportGroupDetailsService.exportGroupDetailsService(assetGroupId, (message: any) => {
      dispatch(exportGroupDetailsByIdUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportGroupDetailsByIdErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportGroupDetailsByIdErrorAction(e))
  }
}

export const createGroup = (createGroupPostRequest: CreateGroupPostRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createGroupLoadingAction())

  void CreateGroupService.createGroup(
    createGroupPostRequest,
    (createGroupApiResponse: CreateGroupApiResponse, httpStatus: number) => {
      dispatch(
        createGroupUpdateAction({
          data: createGroupApiResponse, httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(createGroupErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssetListForCreateGroup = (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  assetTypeId: string,
  searchInput: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetsForCreateGroupLoadingAction())

  void AssetForGroupService.getAssetListForGroup(
    page, size, sortdir, sortfield,
    (assetListForCreateGroup: AssetForGroup, httpStatus: number) => {
      dispatch(
        assetsForCreateGroupUpdateAction({
          data: assetListForCreateGroup,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetsForCreateGroupErrorAction({ errorMessage, httpStatus }))
    },
    '',
    '',
    assetTypeId,
    searchInput
  )
}
export const fetchAssetGroupDetails = (
  groupId: string,
  tenantId: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetGroupDetailsLoadingAction())

  void AssetGroupDetailsService.getAssetGroupDetails(
    groupId,
    tenantId,
    (assetGroupDetails: AssetGroupDetails, httpStatus: number) => {
      dispatch(
        assetGroupDetailsUpdateAction({
          data: assetGroupDetails,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetGroupDetailsErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAssetAssignedList = (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  tenantId: string,
  groupId: string,
  searchInput: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(assetAssignedListLoadingAction())

  void AssetForGroupService.getAssetListForGroup(
    page, size, sortdir, sortfield,
    (assetAssignedList: GroupsAsset, httpStatus: number) => {
      dispatch(
        assetAssignedListUpdateAction({
          data: assetAssignedList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(assetAssignedListErrorAction({ errorMessage, httpStatus }))
    },
    groupId,
    '',
    '',
    searchInput
  )
}

export const updateGroup = (groupId: string, updateGroupRequest: UpdateGroupRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(updateGroupLoadingAction())

  void UpdateGroupService.updateGroup(
    groupId,
    updateGroupRequest,
    (updateGroupApiResponse: UpdateGroupApiResponse, httpStatus: number) => {
      dispatch(
        updateGroupUpdateAction({
          data: updateGroupApiResponse, httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(updateGroupErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchUnassignedAssetList = (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  groupId: string,
  input: string,
  assetTypeId: string,
  searchInput: string
) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(unassignedAssetListLoadingAction())

  void AssetForGroupService.getAssetListForGroup(
    page, size, sortdir, sortfield,
    (unassignedAssetList: GroupsAsset, httpStatus: number) => {
      dispatch(
        unassignedAssetListUpdateAction({
          data: unassignedAssetList,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(unassignedAssetListErrorAction({ errorMessage, httpStatus }))
    },
    groupId,
    input,
    assetTypeId,
    searchInput
  )
}

export const deleteGroup = (groupIds: string | string[]) => async (dispatch: any) => {
  dispatch(deleteGroupLoadingAction())
  void DeleteGroupService.deleteGroupById(groupIds, () => {
    dispatch(deleteGroupUpdateAction())
  },
  () => {
    dispatch(deleteGroupErrorAction())
  })
}
