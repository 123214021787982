import { Box, Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../../components/TablePagination'
import React, { useEffect } from 'react'
import { visuallyHidden } from '@mui/utils'
import { type SelectAssetList } from '../../types'
import { getTemplateModal } from './utils/getTemplateModal'
import { type RootState } from '../../../../store'
import { type ConnectedProps, connect } from 'react-redux'
import { fetchAssetListForCreateGroup } from '../../redux/actionCreator'
import { type AssetsFoCreateGroupState } from '../../redux/assetForCreateGroupSlice'
import { type Option } from '../../../../components/GenXDropdown'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import LanguageStrings from '../../../../i18n/locales'
import SearchBox from '../../../../components/SearchBox'

const SelectAssetsStrings = LanguageStrings().AssetGroupStrings.CreateGroupComponent.SelectAssets

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  dataList: SelectAssetList[]
  order: Order
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof SelectAssetList) => void
}

function EnhancedTableHead (props: EnhancedTableProps): any {
  const { onSelectAllClick, order, orderBy, onRequestSort, numSelected, rowCount, dataList } = props
  const createSortHandler =
        (newOrderBy: keyof SelectAssetList) => (event: React.MouseEvent<unknown>) => {
          onRequestSort(event, newOrderBy)
        }
  if (dataList !== null && dataList !== undefined) {
    return (
      <TableHead data-testid='table-header'>
        <TableRow>
          <StyledTableCell padding="checkbox">
            <Checkbox
                color='primary'
                sx={{ color: '#909090' }}
                data-testid="device-table-header-checkbox"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'Select all Assets',
                  id: 'select-all-items'
                }}
            />
          </StyledTableCell>
          {headCells.map((headCell: HeadCell) => (
              <StyledTableCell
                  key={headCell.id}
                  align='left'
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                    hideSortIcon={!headCell.sortable}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
                    aria-label={headCell.label}
                    data-testid="test-sort"
                >
                    {headCell.label}
                    {orderBy === headCell.id
                      ? (
                        <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        )
                      : null}
                </TableSortLabel>
              </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web',
    lineHeight: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web',
    whiteSpace: 'nowrap'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: SelectAssetsStrings.ASSET_ID,
    sortable: true
  },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: false,
    label: SelectAssetsStrings.SERIAL_NUMBER,
    sortable: true
  },
  {
    id: 'assetType',
    numeric: false,
    disablePadding: false,
    label: SelectAssetsStrings.ASSET_TYPE,
    sortable: false
  },
  {
    id: 'assetConfiguration',
    numeric: false,
    disablePadding: false,
    label: SelectAssetsStrings.ASSET_CONFIGURATION,
    sortable: false
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: SelectAssetsStrings.STATE,
    sortable: false
  },
  {
    id: 'firmware_version',
    numeric: false,
    disablePadding: false,
    label: 'Firmware Version',
    sortable: false
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    label: SelectAssetsStrings.Tag,
    sortable: false
  }
]

interface ParentProps {
  assetType: Option | null
  selectedAssets: string[]
  setSelectedAssets: React.Dispatch<React.SetStateAction<string[]>>
}

const SelectAssetComponent = (props: AllProps): JSX.Element => {
  const [assetList, setAssetList] = React.useState<SelectAssetList[]>([])
  const [showTemplates, setShowTemplates] = React.useState<string[]>([])
  const [searchInput, setSearchInput] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'id' })
  const rowsPerPage = 10

  // istanbul ignore next
  const handleRowClick = (id: string): void => {
    const isIdSelected = props.selectedAssets.includes(id)
    if (!isIdSelected) {
      props.setSelectedAssets([...props.selectedAssets, id])
    } else {
      props.setSelectedAssets(props.selectedAssets.filter((selectedId) => selectedId !== id))
    }
  }

  // istanbul ignore next
  const handleFetchAssetList = (): void => {
    if (props.assetType !== null) {
      page >= 1 && props.fetchAssetListForCreateGroup(page - 1, rowsPerPage, sort.order, sort.orderBy, props.assetType.value, searchInput)
    } else {
      page >= 1 && props.fetchAssetListForCreateGroup(page - 1, rowsPerPage, sort.order, sort.orderBy, '', searchInput)
    }
  }

  useEffect(() => {
    handleFetchAssetList()
  }, [page, sort])

  // istanbul ignore next
  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof SelectAssetList): void => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  useEffect(() => {
    if (props.assetForCreateGroup.assetsForCreateGroup !== undefined && props.assetForCreateGroup.httpStatus === 200) {
      setAssetList(props.assetForCreateGroup.assetsForCreateGroup.group_details)
    }
  }, [props.assetForCreateGroup.assetsForCreateGroup])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentPageAssets = assetList.map((asset: SelectAssetList) => asset.asset_id)
    // istanbul ignore next
    if (event.target.checked) {
      const newSelected: string[] = []
      currentPageAssets.forEach((id) => {
        if (!props.selectedAssets.includes(id)) {
          newSelected.push(id)
        }
      })
      props.setSelectedAssets([...props.selectedAssets, ...newSelected])
    } else {
      const newSelected: string[] = []
      props.selectedAssets.forEach((id) => {
        if (!currentPageAssets.includes(id)) {
          newSelected.push(id)
        }
      })
      props.setSelectedAssets(newSelected)
    }
  }

  function getShortenedName (name: string): JSX.Element {
    if (name.length > 15) {
      return <abbr style={{ textDecoration: 'none' }} title={name}>{name.slice(0, 20)}...</abbr>
    }
    return <>{name}</>
  }

  const isSelected = (id: string): boolean => props.selectedAssets.includes(id)

  const getSelectAssetsWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.assetForCreateGroup.assetsForCreateGroup !== undefined && props.assetForCreateGroup.assetsForCreateGroup.group_details.length > 0 && props.assetForCreateGroup.httpStatus === 200) {
      return (
        <>
          <Box sx={style} className="devices">
            <Paper
              sx={{
                width: '100%',
                mb: 2,
                backgroundColor: '#272727',
                boxShadow: 'unset',
                color: '#F0F0F0',
                marginBottom: '0'
              }}
            >
              <TableContainer className="device-table">
                <Table
                  sx={{ minWidth: 750, color: '#f0f0f0' }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <EnhancedTableHead
                    numSelected={props.selectedAssets.filter((id) => assetList.map((asset: SelectAssetList) => asset.asset_id).includes(id)).length}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={assetList.length}
                    dataList={assetList}
                  />
                  <TableBody>
                    {assetList.length > 0 &&
                      assetList.map((row: SelectAssetList, index: number) => {
                        const isItemSelected = isSelected(row.asset_id)
                        const labelId = `enhanced-table-checkbox-${row.asset_id}`

                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.asset_id}
                            aria-label="groups-list-row"
                            selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <StyledTableCell padding="checkbox">
                                <Checkbox
                                    sx={{ color: '#909090' }}
                                    checked={isItemSelected}
                                    onChange={() => { handleRowClick(row.asset_id) }}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                      'aria-label': 'test-checkbox',
                                      id: `select-item-${index}`
                                    }}
                                />
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              aria-label="group-name"
                              data-testid="group-name"
                              sx={{
                                color: '#64C3FF !important',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '25vw'
                              }}
                            >
                              {row.asset_id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.serial_number}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.asset_type}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.template_name === null
                                ? ''
                                : row.template_name.length === 1
                                  ? row.template_name[0]
                                  : <span onClick={() => { setShowTemplates(row.template_name === null ? [] : row.template_name) }} style={{ color: '#64C3FF' }}>{row.template_name[0]}+{row.template_name.length - 1}</span>
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.state}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.firmware_version}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.tag.length > 0 ? getShortenedName(row.tag[0]) : ''}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination count={props.assetForCreateGroup.assetsForCreateGroup.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
                          boundaryCount={1} />
            </Paper>
          </Box>
          {
            showTemplates.length > 0
              ? getTemplateModal(showTemplates, () => { setShowTemplates([]) }, 'Asset Configuration')
              : <></>
          }
        </>
      )
    } else if (props.assetForCreateGroup.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (props.assetForCreateGroup.httpStatus === 401) {
      return (
              <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.assetForCreateGroup.httpStatus === 403) {
      return (
          <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else if (((props.assetForCreateGroup.assetsForCreateGroup === undefined || props.assetForCreateGroup.assetsForCreateGroup.group_details.length === 0) && props.assetForCreateGroup.httpStatus === 200) || (props.assetForCreateGroup.httpStatus === 404 && props.assetForCreateGroup.error === 'No Assets Found')) {
      return (
          <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (props.assetForCreateGroup.httpStatus === 400) {
      return (
        <div className='authError'><AuthError errorMessage="invalidSearchInput"/></div>
      )
    } else {
      return (
          <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchAssetList}/></div>
      )
    }
  }

  return (
    <>
      <div>
        <p id='group-info-title'>{SelectAssetsStrings.SELECT_ASSETS}</p>
        <p style={{
          borderTop: '2px solid #202020',
          borderBottom: '2px solid #202020',
          paddingTop: 10,
          paddingBottom: 10,
          marginBottom: 15,
          color: '#f0f0f0',
          fontSize: '16px'
        }}>{SelectAssetsStrings.Asset_List}
          <span style={{ borderLeft: '1px solid #707070', paddingLeft: 10, marginLeft: 10 }}>
            {props.assetForCreateGroup.assetsForCreateGroup !== undefined ? props.assetForCreateGroup.assetsForCreateGroup.total_count : 0} {SelectAssetsStrings.Assets}
          </span>
        </p>
      </div>
      <SearchBox sx={{ width: '384px' }}
          immediate={true}
          debounce={true}
          placeholder='Search using Serial Number, State, Tag, Firmware'
          emptyEnterFallback={handleFetchAssetList}
          searchVal={searchInput} setSearchVal={setSearchInput}
          performAction={handleFetchAssetList}
        />
      {getSelectAssetsWithFailure()}
    </>
  )
}

interface DispatchToProps {
  fetchAssetListForCreateGroup: (page: number, size: number, sortdir: string, sortfield: string, assetTypeId: string, searchInput: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetListForCreateGroup: (page: number, size: number, sortdir: string, sortfield: string, assetTypeId: string, searchInput: string) => dispatch(fetchAssetListForCreateGroup(page, size, sortdir, sortfield, assetTypeId, searchInput))
})

interface StateToProps {
  assetForCreateGroup: AssetsFoCreateGroupState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetForCreateGroup: state.assetForCreateGroup
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type AllProps = ParentProps & PropsFromRedux

export default connector(SelectAssetComponent)
