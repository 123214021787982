import { Box, Grid, Modal, Typography } from '@mui/material'
import Water_Merlin from '../../../assets/devices/WaterMerlinNBOT.svg'
import Q600 from '../../../assets/devices/Q600.svg'
import { type AssetByIdState } from '../redux/assetByIdSlice'
import DeviceUtil from '../../../utils/DeviceUtil'
import DefaultImage from '../../../assets/devices/No-Image-Placeholder.svg'
import HBG3 from '../../../assets/devices/HBG3.svg'
import V200H from '../../../assets/devices/V200H.svg'
import GASNXCM from '../../../assets/devices/NXCM_AC250.svg'
import ThemisDuo from '../../../assets/devices/ThemisDuo.png'
import Y290 from '../../../assets/devices/Y290.png'
import Contazara from '../../../assets/devices/Contazara.png'
import TSherpaBridge from '../../../assets/devices/TSherpaBridge.png'
import React, { useCallback, useEffect, useRef } from 'react'
import LanguageStrings from '../../../i18n/locales'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type RootState } from '../../../store'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { fetchAssetData, fetchDeviceHistory } from '../redux/actionCreator'
import { type IPConfigState } from '../redux/ipConfigSlice'
import { getOperationPermissions } from '../../dashboard/redux/actionCreator'
import { type OperationPermissionState } from '../../dashboard/redux/operationPermissionSlice'
import { useAuth } from 'react-oidc-context'
import CloseIcon from '@mui/icons-material/Close'
import { type DeviceHistory } from '../services/deviceLifeCycleHistoryService'
import { type DeviceHistoryState } from '../redux/deviceLifeCycleHistorySlice'
import { convertDateIntoAohStandardUsingDayjs, parseDateInAOHstandard } from '../../../customHooks/parseDate'

const assetValuesStyles = {
  color: '#F0F0F0',
  fontWeight: '500'
}

const modalStyleState = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4,
  borderRadius: '5px'
}

const ParameterListComponentStrings = LanguageStrings().AssetConfigurationStrings.components.AssetDetailsComponents.ParameterListComponent

export function getDeviceImage (device: any): string {
  // istanbul ignore else
  if (device != null) {
    if (DeviceUtil.getDeviceTypeByName(device)?.isWater === true) {
      if (DeviceUtil.getDeviceTypeByName(device)?.isQ600 === true) {
        return Q600
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isHBG3 === true) {
        return HBG3
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isV200H === true) {
        return V200H
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isY290 === true) {
        return Y290
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isTSherpaBridge === true) {
        return TSherpaBridge
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isCz === true) {
        return Contazara
      }
      return Water_Merlin
    } else if (DeviceUtil.getDeviceTypeByName(device)?.isGas === true) {
      if (DeviceUtil.getDeviceTypeByName(device)?.isGasNxcm === true) {
        return GASNXCM
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isThemisDuo === true) {
        return ThemisDuo
      }
      return GASNXCM
    }
  }
  return DefaultImage
}

const AssetSummary = (props: AllProps): JSX.Element => {
  const operationPermissions: OperationPermissionState = useSelector((state: RootState) => state.operationPermissions)
  const [showHistoryDetailsModal, setshowHistoryDetailsModal] = React.useState(false)
  const historyTableRef = useRef<HTMLDivElement | null>(null)
  const auth = useAuth()
  React.useEffect(() => {
    if (!operationPermissions.updated) {
      props.getOperationPermissions(auth.user?.profile.sub as string)
    }
  }, [])

  // istanbul ignore next
  const setRef = useCallback((node: HTMLDivElement | null) => {
    if (historyTableRef.current != null) {
      // Make sure to cleanup any events/references added to the last instance
      historyTableRef.current.removeEventListener('keydown', handleKeyDown)
    }

    if (node != null) {
      // Add event listeners or any instance specific tasks here
      node.addEventListener('keydown', handleKeyDown)
      node.focus()
    }

    // Save a reference to the node
    historyTableRef.current = node
  }, [])

  // istanbul ignore next
  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'ArrowDown') {
      historyTableRef.current?.scrollBy(0, 50)
    } else if (e.key === 'ArrowUp') {
      historyTableRef.current?.scrollBy(0, -50)
    }
  }

  const displayStateHistory = (): void => {
    setshowHistoryDetailsModal(true)
  }

  // istanbul ignore next
  const closedisplayStateHistory = (): void => {
    setshowHistoryDetailsModal(false)
  }

  useEffect(() => {
    props.fetchDeviceHistory(props.assetData.assetData?.id?.toString() ?? '', auth.user?.profile.customer as string)
  }, [])

  if (props.assetData.httpStatus === 401) {
    return (
      <div className="authError">
        <AuthError errorMessage="Unauthorized" />
      </div>
    )
  } else if (props.assetData.httpStatus === 403) {
    return (
      <div className="authError">
        <AuthError errorMessage="accessForbidden" />
      </div>
    )
  } else {
    return (
    <Grid container style={{ paddingTop: '20px' }}>
      <Grid xs={12} sm={2}>
        <div
          style={{
            padding: '10px',
            backgroundColor: 'white',
            height: 'fit-content',
            borderRadius: '10px',
            width: 'fit-content'
          }}
        >
          <img
            src={
              getDeviceImage(props.assetData.assetData?.device_type)
            }
            style={{ width: '100px', height: 'auto' }}
          />
        </div>
      </Grid>
      <Grid container xs={12} sm={10}>
        <Grid container className='assetSummaryRow'>
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>{props.OverviewStrings.Asset_Type}</Grid>
            <Grid className='assetSummaryListContent'>
              {props.assetData.assetData?.device_type}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
          <Grid className='assetSummaryListHead'>{props.OverviewStrings.State}</Grid>
            <Grid className='assetSummaryListContent' style={assetValuesStyles}>{props.assetData.assetData?.asset_state} <span style={{ color: '#64C3FF', cursor: 'pointer' }} onClick={() => { displayStateHistory() }} data-testid= "view-history">(View History)</span></Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>{props.OverviewStrings.Year_of_Certification}</Grid>
            <Grid className='assetSummaryListContent'>None</Grid>
          </Grid>
        </Grid>
        <Grid container className='assetSummaryRow'>
          {/* <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>{props.OverviewStrings.Asset_Configuration}</Grid>
            <Grid className='assetSummaryListContent'>None</Grid>
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>Tag</Grid>
            <Grid className='assetSummaryListContent'>{props.assetData.assetData?.tag[0]?.name !== null && props.assetData.assetData?.tag[0]?.name !== undefined && props.assetData.assetData?.tag[0]?.name !== '' ? props.assetData.assetData?.tag[0]?.name : 'Not Available' } </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>{props.OverviewStrings.Serial_Number}</Grid>
            <Grid className='assetSummaryListContent' style={assetValuesStyles}>{props.assetData.assetData?.comm_mod_serial ?? ParameterListComponentStrings.Not_Applicable}</Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>{props.OverviewStrings.Installation_Date}</Grid>
            <Grid className='assetSummaryListContent'>
              {props.assetData.assetData?.install_date !== null &&
              props.assetData.assetData?.install_date !== undefined
                ? convertDateIntoAohStandardUsingDayjs(
                  props.assetData.assetData?.install_date
                )
                : '----'}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className='assetSummaryRow'>
        <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>Meter Serial Number</Grid>
            <Grid className='assetSummaryListContent'>
              {props.assetData.assetData?.serial_number}
            </Grid>
        </Grid >
        {(((props.assetData.assetData?.device_type.startsWith('Water')) === true) || ((props.assetData.assetData?.device_type.startsWith('Gas')) === true)) && (
          <>
              <Grid item xs={12} sm={3}>
                  <Grid className='assetSummaryListHead'>Meter Date of Expiry</Grid>
                  <Grid className='assetSummaryListContent'>
                    None
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Grid className='assetSummaryListHead'>Remaining Battery Percentage</Grid>
                  <Grid className='assetSummaryListContent'>
                  {((props.assetData.assetData?.rem_bat_percent) != null) ? props.assetData.assetData?.rem_bat_percent?.toString() : 'Not Available'}
                  </Grid>
                </Grid>
          </>
        )}
        </Grid>
        {(((props.assetData.assetData?.device_type.startsWith('Water')) === true) || ((props.assetData.assetData?.device_type.startsWith('Gas')) === true)) && (
        <Grid container className='assetSummaryRow'>
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>Meter Date of Shipment</Grid>
            <Grid className='assetSummaryListContent'>
              None
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>Firmware Version</Grid>
            <Grid className='assetSummaryListContent'>
              {props.assetData.assetData?.firmware_version !== null ? props.assetData.assetData?.firmware_version : 'Not Available'}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid className='assetSummaryListHead'>IP & Port</Grid>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className='assetSummaryListContent' style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                   { props.assetData.assetData?.ip_address !== null && props.assetData.assetData?.ip_address !== '' ? props.assetData.assetData?.ip_address : 'Not Available' } : { props.assetData.assetData?.port !== null && props.assetData.assetData?.port !== '' ? props.assetData.assetData?.port : 'Not Available' }
                  </span>
                </div>
          </Grid>
          <div>
      <Modal className='Edit-AssetSummary-Modal deviceHistory-modal' open={showHistoryDetailsModal} onClose={() => { closedisplayStateHistory() }}>
        <Box sx={modalStyleState}>
        <div className='title' style={{ marginBottom: '30px' }}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              State History
            </Typography>
            <CloseIcon data-testid='Close-Button-Model'
              style={{ cursor: 'pointer' }}
              onClick={() => { closedisplayStateHistory() }}
            />
        </div>
        <div ref={setRef} tabIndex={0} style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <table className='stateHistory-table' style={{ width: '100%', padding: '10px 5px 0 0' }}>
            <tbody>
              <tr>
                <th style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{ParameterListComponentStrings.FromState}</th>
                <th style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{ParameterListComponentStrings.ToState}</th>
                <th style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{ParameterListComponentStrings.TransitionDate}</th>
                <th style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{ParameterListComponentStrings.StateEndDate}</th>
                <th style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{ParameterListComponentStrings.UpdatedBy}</th>
              </tr>
              {
                props.deviceHistory.deviceHistory !== undefined
                  ? props.deviceHistory.deviceHistory.map((row: DeviceHistory, index: number) => (
                      <tr key={index}>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{row.state_from}</td>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{row.state_to}</td>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{row.transition_time !== null ? parseDateInAOHstandard(row.transition_time) : ''}</td>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{row.state_end_date !== null ? parseDateInAOHstandard(row.state_end_date) : ''}</td>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', height: '40px', border: '1px solid #737373' }}>{row.user_details}</td>
                      </tr>
                  ))
                  : <></>
                }
            </tbody>
          </table>
        </div>
        </Box>
      </Modal>
          </div>
        </Grid>
        )}
        <Grid
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}
          container
        >
        </Grid>
      </Grid>
    </Grid>
    )
  }
}

interface DispatchToProps {
  fetchAssetData: (id: string, tenantId: string) => void
  getOperationPermissions: (sub: string) => void
  fetchDeviceHistory: (assetId: string, tenantId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetData: (id: string, tenantId: string) => dispatch(fetchAssetData(id, tenantId)),
  getOperationPermissions: (sub: string) => dispatch(getOperationPermissions(sub)),
  fetchDeviceHistory: (assetId: string, tenantId: string) => dispatch(fetchDeviceHistory(assetId, tenantId))
})

interface StateToProps {
  ipConfigState: IPConfigState
  assetId: string
  deviceHistory: DeviceHistoryState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  ipConfigState: state.ipConfigState,
  assetId: state.assetId,
  deviceHistory: state.deviceHistory
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

interface ParentProps {
  assetData: AssetByIdState
  OverviewStrings: any
}

type AllProps = PropsFromRedux & ParentProps

export default connector(AssetSummary)
