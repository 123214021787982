/* @typescript-eslint/restrict-template-expressions */
import { Box, Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../../components/TablePagination'
import React, { useEffect } from 'react'
import { visuallyHidden } from '@mui/utils'
import { type UpdateGroupRequest, type Asset, type GroupsAssetObj } from '../../types'
import LanguageStrings from '../../../../i18n/locales'
import SearchBox from '../../../../components/SearchBox'
import { type UnassignedAssetListState } from '../../redux/UnassignedAssetListSlice'
import { type RootState } from '../../../../store'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { type ConnectedProps, connect } from 'react-redux'
import { fetchUnassignedAssetList, updateGroup } from '../../redux/actionCreator'
import { Button } from '../../../../components/Button'
import { getTemplateModal } from '../createGroupComponents/utils/getTemplateModal'

const AssetAssignedStrings = LanguageStrings().AssetGroupStrings.GroupDetailsComponent.AssignedAssetList

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0,
  maxHeight: 'calc(90vh - 176px)',
  overflowY: 'auto'
}
interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  dataList: GroupsAssetObj[]
  order: Order
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof Asset) => void
}

function EnhancedTableHead (props: EnhancedTableProps): any {
  const { onSelectAllClick, order, orderBy, onRequestSort, numSelected, rowCount, dataList } = props
  const createSortHandler =
        (newOrderBy: keyof Asset) => (event: React.MouseEvent<unknown>) => {
          onRequestSort(event, newOrderBy)
        }
  return (dataList !== null && dataList !== undefined && <>
    <TableHead data-testid='table-header'>
        <TableRow>
          <StyledTableCell padding="checkbox">
            <Checkbox
                color='primary'
                sx={{ color: '#909090' }}
                data-testid="device-table-header-checkbox"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'Select all Unassigned Assets',
                  id: 'select-all-items'
                }}
            />
          </StyledTableCell>
          {headCells.map((headCell: HeadCell) => (
              <StyledTableCell
                  key={headCell.id}
                  align='left'
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                    hideSortIcon={!headCell.sortable}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
                    aria-label={headCell.label}
                    data-testid="test-sort"
                >
                    {headCell.label}
                    {orderBy === headCell.id
                      ? (
                        // istanbul ignore next
                        <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        )
                      : null}
                </TableSortLabel>
              </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web',
    whiteSpace: 'nowrap',
    lineHeight: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web',
    whiteSpace: 'nowrap'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'assetId',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.AssetId,
    sortable: true
  },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.SerialNumber,
    sortable: true
  },
  {
    id: 'assetType',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.AssetType,
    sortable: false
  },
  {
    id: 'assetConfiguration',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.AssetConfiguration,
    sortable: false
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.State,
    sortable: false
  },
  {
    id: 'firmware_version',
    numeric: false,
    disablePadding: false,
    label: 'Firmware Version',
    sortable: false
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    label: AssetAssignedStrings.Tag,
    sortable: false
  }
]

const UnassignedAssetListTable = (props: AllProps): JSX.Element => {
  const [unassignedAssetList, setUnassignedAssetList] = React.useState<GroupsAssetObj[]>([])
  const [tempAddedAssets, setTempAddedAssets] = React.useState<GroupsAssetObj[]>([])
  const [searchInput, setSearchInput] = React.useState('')
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'id' })
  const rowsPerPage = 10
  const [showTemplates, setShowTemplates] = React.useState<string[]>([])

  useEffect(() => {
    setTempAddedAssets(props.addedAssets)
    const tempSelected: string[] = []
    props.addedAssets.forEach(asset => {
      // istanbul ignore next
      tempSelected.push(asset.asset_id)
    })
    setSelected(tempSelected)
  }, [])

  // istanbul ignore next
  const handleRowClick = (asset: GroupsAssetObj): void => {
    const isIdSelected = selected.includes(asset.asset_id)
    if (!isIdSelected) {
      setTempAddedAssets([...tempAddedAssets, asset])
      setSelected([...selected, asset.asset_id])
    } else {
      setTempAddedAssets(tempAddedAssets.filter((selectedAsset) => selectedAsset.asset_id !== asset.asset_id))
      setSelected(selected.filter((selectedId) => selectedId !== asset.asset_id))
    }
  }

  const handleFetchUnassignedAssetList = (): void => {
    let groupId = sessionStorage.getItem('selectedGroupId')
    // istanbul ignore next
    if (groupId === null) {
      groupId = ''
    }
    page >= 1 && props.fetchUnassignedAssetList(page - 1, rowsPerPage, sort.order, sort.orderBy, groupId, 'unassigned', props.assetTypeId, searchInput)
  }

  useEffect(() => {
    handleFetchUnassignedAssetList()
  }, [page, sort])

  // istanbul ignore next
  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Asset): void => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  useEffect(() => {
    if (props.unassignedAssetList.unassignedAssetList !== null && props.unassignedAssetList.httpStatus === 200) {
      setUnassignedAssetList(props.unassignedAssetList.unassignedAssetList.group_details)
    }
  }, [props.unassignedAssetList.unassignedAssetList])

  // istanbul ignore next
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentPageAssets = unassignedAssetList.map((asset: GroupsAssetObj) => asset.asset_id)
    if (event.target.checked) {
      const newSelected: string[] = []
      const newSelectedAssets: GroupsAssetObj[] = []
      currentPageAssets.forEach((id) => {
        if (!selected.includes(id)) {
          newSelected.push(id)
          newSelectedAssets.push(unassignedAssetList.filter(asset => asset.asset_id === id)[0])
        }
      })
      setTempAddedAssets([...tempAddedAssets, ...newSelectedAssets])
      setSelected([...selected, ...newSelected])
    } else {
      const newSelected: string[] = []
      const newSelectedAssets: GroupsAssetObj[] = []
      selected.forEach((id) => {
        if (!currentPageAssets.includes(id)) {
          newSelected.push(id)
          newSelectedAssets.push(unassignedAssetList.filter(asset => asset.asset_id === id)[0])
        }
      })
      setSelected(newSelected)
      setTempAddedAssets(newSelectedAssets)
    }
  }

  const handleAddClick = (): void => {
    props.setAddedAssets(tempAddedAssets)
    props.setShowAddAssetWindow(false)
  }

  const isSelected = (id: string): boolean => selected.includes(id)

  const getAssetTableWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.unassignedAssetList.unassignedAssetList !== null && props.unassignedAssetList.unassignedAssetList.group_details.length > 0 && props.unassignedAssetList.httpStatus === 200) {
      return (
        <>
          <Box sx={style} className="devices">
            <Paper
              sx={{
                width: '100%',
                mb: 2,
                backgroundColor: '#272727',
                boxShadow: 'unset',
                color: '#F0F0F0',
                marginBottom: '0'
              }}
            >
              <TableContainer className="device-table">
                <Table
                  sx={{ minWidth: 750, color: '#f0f0f0' }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <EnhancedTableHead
                    numSelected={selected.filter((id) => unassignedAssetList.map((asset: GroupsAssetObj) => asset.asset_id).includes(id)).length}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={unassignedAssetList.length}
                    dataList={unassignedAssetList}
                  />
                  <TableBody>
                    {unassignedAssetList.length > 0 &&
                      unassignedAssetList.map((row: GroupsAssetObj, index: number) => {
                        const isItemSelected = isSelected(row.asset_id)
                        const labelId = `enhanced-table-checkbox-${row.asset_id}`
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.asset_id}
                            aria-label="groups-list-row"
                            selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <StyledTableCell className='responsive-font' padding="checkbox">
                                <Checkbox
                                    sx={{ color: '#909090' }}
                                    checked={isItemSelected}
                                    onChange={
                                      // istanbul ignore next
                                      () => { handleRowClick(row) }}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                      'aria-label': 'test-checkbox',
                                      id: `select-item-${index}`
                                    }}
                                />
                            </StyledTableCell>
                            <StyledTableCell className='responsive-font'
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              aria-label="group-name"
                              data-testid="group-name"
                              sx={{
                                color: '#64C3FF !important',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '25vw'
                              }}
                            >
                              {row.asset_id}
                            </StyledTableCell>
                            <StyledTableCell className='responsive-font'>
                              {row.serial_number}
                            </StyledTableCell>
                            <StyledTableCell className='responsive-font'>
                              {row.asset_type}
                            </StyledTableCell>
                            <StyledTableCell className='responsive-font'>
                                {row.template_name !== null && (
                                  row.template_name.length === 1
                                    ? row.template_name[0]
                                    : <span data-testid={`assetConfig-${index}`} onClick={() => { setShowTemplates(row.template_name === null ? [] : row.template_name) }} style={{ color: '#64C3FF' }}>{row.template_name[0]}+{row.template_name.length - 1}</span>
                                )
                                }
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.state}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.firmware_version}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row?.tag?.length > 0 && row.tag[0]}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination count={props.unassignedAssetList.unassignedAssetList.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
                          boundaryCount={1} />
            </Paper>
          </Box>
          {
          showTemplates.length > 0
            ? getTemplateModal(showTemplates, () => { setShowTemplates([]) }, 'Configurations', 'CloseTemplatePopup')
            : <></>
          }
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Button sx={{ margin: 10 }} dataTestId='unassigned-add-btn' onClick={handleAddClick} disabled={selected.length === 0} primaryColor={selected.length !== 0 ? undefined : '#B0B0B0'}>Add</Button>
          </div>
        </>
      )
    } else if (props.unassignedAssetList.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (props.unassignedAssetList.httpStatus === 401) {
      return (
              <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.unassignedAssetList.httpStatus === 403) {
      return (
          <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else if (((props.unassignedAssetList.unassignedAssetList === null || props.unassignedAssetList.unassignedAssetList.group_details.length === 0) && props.unassignedAssetList.httpStatus === 200) || (props.unassignedAssetList.httpStatus === 404 && props.unassignedAssetList.error === 'No Assets Found')) {
      return (
          <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (props.unassignedAssetList.httpStatus === 400) {
      return <div className="authError"><AuthError errorMessage="invalidSearchInput" /></div>
    } else {
      return (
          <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchUnassignedAssetList}/></div>
      )
    }
  }

  return (
    <>
      <SearchBox sx={{ width: '420px' }}
          immediate={true}
          debounce={true}
          placeholder='Search using Serial Number, State, Tag, Firmware'
          emptyEnterFallback={handleFetchUnassignedAssetList}
          searchVal={searchInput} setSearchVal={setSearchInput}
          performAction={handleFetchUnassignedAssetList}
        />
      {getAssetTableWithFailure()}
    </>
  )
}

interface DispatchToProps {
  updateGroup: (groupId: string, updateGroupRequest: UpdateGroupRequest) => void
  fetchUnassignedAssetList: (page: number, size: number, sortdir: string, sortfield: string, groupId: string, input: string, assetTypeId: string, searchInput: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  updateGroup: (groupId: string, updateGroupRequest: UpdateGroupRequest) => dispatch(updateGroup(groupId, updateGroupRequest)),
  fetchUnassignedAssetList: (page: number, size: number, sortdir: string, sortfield: string, groupId: string, input: string, assetTypeId: string, searchInput: string) => dispatch(fetchUnassignedAssetList(page, size, sortdir, sortfield, groupId, input, assetTypeId, searchInput))
})

interface StateToProps {
  unassignedAssetList: UnassignedAssetListState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  unassignedAssetList: state.UnassignedAssetList
})

const connector = connect(mapStateToProps, mapDispatchToProps)
interface ParentProps {
  assetTypeId: string
  addedAssets: GroupsAssetObj[]
  setAddedAssets: React.Dispatch<React.SetStateAction<GroupsAssetObj[]>>
  setShowAddAssetWindow: React.Dispatch<React.SetStateAction<boolean>>
}
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = ParentProps & PropsFromRedux

export default connector(UnassignedAssetListTable)
