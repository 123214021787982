import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { ZAssetForGroup, type AssetForGroup } from '../types'

const getFields = (sortfield: string, groupId: string, input: string, assetTypeId: string): string => {
  let field = sortfield
  if (field === 'assetId') {
    field = 'id'
  } else if (field === 'state') {
    field = 'stateName'
  }
  if (assetTypeId !== '' && input === '' && groupId === '') {
    if (field === 'tag') {
      field = 'tag.name'
    }
  } else if (assetTypeId === '' && input === '' && groupId !== '') {
    // istanbul ignore else
    if (field === 'id') {
      field = 'assetId'
    }
  }
  return field
}

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZAssetForGroup.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function getAssetListForGroup (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  onSuccess: (assetForGroup: AssetForGroup, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any,
  groupId: string,
  input: string,
  assetTypeId: string,
  searchInput: string
): Promise<void> {
  const field = getFields(sortfield, groupId, input, assetTypeId)
  const url = `/assetrest/api/v1/udp/groupManagement/asset-group/assets?groupId=${groupId !== undefined ? groupId : ''}&input=${input !== undefined ? input : ''}&searchAssetsInput=${searchInput}&assetTypeId=${assetTypeId !== undefined ? assetTypeId : ''}&page=${page}&size=${size}&sort=${field},${sortdir}`

  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.error_message, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
