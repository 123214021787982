/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react'
import Pagination from '../../../../components/TablePagination'
import { type SnackbarOrigin } from '@mui/material/Snackbar'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import { visuallyHidden } from '@mui/utils'
import LanguageStrings from '../../../../i18n/locales'
export interface State extends SnackbarOrigin {
  open: boolean
}
interface Data {
  register: string
  dataUntil: string
  latestValue: number
  action: number
}

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

type Order = 'asc' | 'desc'
interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const TableStrings = LanguageStrings().DatasourceStrings.DatasourceRegTable
const headCells: readonly HeadCell[] = [
  {
    id: 'REGISTER',
    numeric: false,
    disablePadding: false,
    label: TableStrings.register
  },
  {
    id: 'DATA UNTIL',
    numeric: false,
    disablePadding: false,
    label: TableStrings.dataUntil
  },
  {
    id: 'LATEST VALUE',
    numeric: false,
    disablePadding: false,
    label: TableStrings.latestValue
  },
  {
    id: 'ACTION',
    numeric: false,
    disablePadding: false,
    label: TableStrings.action
  }
]

function EnhancedTableHead (props: any): any {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, deviceList } = props
  const createSortHandler =
        (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
          onRequestSort(event, newOrderBy)
        }
  if (deviceList !== null && deviceList !== undefined) {
    return (
        <TableHead data-testid="table-header">
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        sx={{ color: '#909090' }}
                        data-testid="device-table-header-checkbox"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all devices',
                          id: 'select-all-items'
                        }}
                    />
                </StyledTableCell>
                {headCells.map((headCell: HeadCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            aria-label={headCell.label}
                            data-testid="test-sort"

                        >
                            {headCell.label}
                            {orderBy === headCell.id
                              ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                                )
                              : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    )
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface Sort {
  order: Order
  orderBy: string
}

function DeviceList (): JSX.Element {
  const rowsPerPage = 5
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'id' })
  const deviceList = [
    {
      id: '1',
      register: 'register 1',
      dataUntil: '--',
      latestValue: '--',
      action: 'Edit'
    },
    {
      id: '2',
      register: 'register 2',
      dataUntil: '--',
      latestValue: '--',
      action: 'Edit'
    },
    {
      id: '3',
      register: 'register 3',
      dataUntil: '--',
      latestValue: '--',
      action: 'Edit'
    },
    {
      id: '4',
      register: 'register 4',
      dataUntil: '--',
      latestValue: '--',
      action: 'Edit'
    },
    {
      id: '5',
      register: 'register 5',
      dataUntil: '--',
      latestValue: '--',
      action: 'Edit'
    }
  ]

  // istanbul ignore next
  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Data): any => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  // istanbul ignore next
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): any => {
    if (event.target.checked) {
      const newSelected: any = deviceList.map((n: any) => n.deviceId)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))

  const isSelected = (name: string): any => selected.includes(name)
  return (
        <>
            <Box sx={style} className="devices">
                <Paper sx={{ width: '100%', mb: 2, backgroundColor: '#272727', boxShadow: 'unset', color: '#F0F0F0', marginBottom: '0' }}>
                    <TableContainer className='device-table'>
                        <Table
                            sx={{ minWidth: 750, color: '#f0f0f0' }}
                            aria-labelledby="tableTitle"
                            size={'small'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={sort.order}
                                orderBy={sort.orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rowsPerPage}
                                deviceList={deviceList}
                            />
                            <TableBody>
                                {deviceList.map((row: any, index: any) => {
                                  const isItemSelected = isSelected(row.id)
                                  const labelId = `enhanced-table-checkbox-${index}`

                                  return (
                                        <StyledTableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.deviceId}
                                            aria-label="devices-list-row"
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <StyledTableCell padding="checkbox">
                                                <Checkbox
                                                    sx={{ color: '#909090' }}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                      'aria-labelledby': labelId,
                                                      'aria-label': 'test-checkbox',
                                                      id: `select-item-${index}`
                                                    }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            aria-label='device-name'
                                            data-testid='device-name'
                                            sx={{ color: '#64C3FF !important' }} >{row.register}</StyledTableCell>
                                            <StyledTableCell
                                            >{row.dataUntil}</StyledTableCell>
                                            <StyledTableCell
                                            >{row.latestValue}</StyledTableCell>
                                            <StyledTableCell >{row.lastUpdated}</StyledTableCell>
                                            <StyledTableCell sx={{ color: '#64C3FF !important' }} >{row.action}</StyledTableCell>
                                        </StyledTableRow>
                                  )
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination count={deviceList.length} page={page} setPage={setPage} rowsPerPage={rowsPerPage} />
                </Paper>
            </Box>

        </>
  )
}

export default DeviceList
