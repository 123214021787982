import React from 'react'
import BackButton from '../../components/BackButton'
import LanguageStrings from '../../i18n/locales'
import { useNavigate } from 'react-router-dom'
import './AssetGroup.css'
import GroupsTableComponent from './components/GroupsTableComponent'
import { useSelector } from 'react-redux'
import { type RootState } from '../../store'
import { Button } from '../../components/Button'
const AssetGroupStrings = LanguageStrings().AssetGroupStrings.AssetGroupMainComponentStrings

const AssetGroupMainComponent = (): JSX.Element => {
  const navigate = useNavigate()
  const numGroups = useSelector((state: RootState) => state.assetGroupList.assetGroupsList !== null ? state.assetGroupList.assetGroupsList.total_count : 0)

  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '1rem' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/card') }} className='assetmgmtli'>{AssetGroupStrings.Asset_Management}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{AssetGroupStrings.AssetGroup}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton onclick={() => { navigate('/card') }}/>
        <span id='componentTitle' data-testid='configurations'>{AssetGroupStrings.AssetGroup}</span>
        <span id='top-right-ami-utilities'>
          <span id='top-right-btn-span'>
            <Button sx={{ margin: 0 }} onClick={() => { navigate('/create-asset-group') }} width='fit-content' dataTestId='create-group-btn'>{AssetGroupStrings.CreateAssetGroup}</Button>
          </span>
        </span>
      </div>
      <div>
        <div className='configurations-table-heading'>
          <h5>{AssetGroupStrings.AssetGroupList} <span className='config-list-box'>{numGroups} {AssetGroupStrings.Groups}</span></h5>
        </div>
        <GroupsTableComponent />
      </div>
    </div>
  )
}

export default AssetGroupMainComponent
