import React, { useEffect } from 'react'
import { type CreateReportData, type CreateColumnContent, type ReportData } from '../../types'
import ReportContentsTable from './ReportContentsTable'
import { Button } from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import { CircularProgress, FormHelperText, TextField } from '@mui/material'
import { isValidText } from '../../../../utils/validator'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import ModifyReportContentForm from '../createNewReportTemplateComponents/ModifyReportContentForm'
import _ from 'lodash'
import LanguageStrings from '../../../../i18n/locales'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../../store'
import { deleteReportTemplate, editReportTemplate, fetchReportContentColumns } from '../../redux/actionCreators'
import { type ReportContentColumnState } from '../../redux/reportContentColumnSlice'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { type DeleteReportTemplateState } from '../../redux/deleteReportTemplateSlice'
import { type EditReportTemplateState } from '../../redux/editReportTemplateSlice'

const ReportTemplateDetailsStrings = LanguageStrings().DataPublisherStrings.ReportTemplateComponents.ReportTemplateDetails

const textStyle = {
  root: {
    backgroundColor: '#272727',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      backgroundColor: '#404040'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

interface ParentProps {
  selectedTemplate: ReportData | undefined
  templateDetailsList: ReportData[]
  handleFetchTemplateDetailsList: () => void
  reportTemplateDetailsRef: React.RefObject<HTMLDivElement>
  editEnabled: boolean
  setEditEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const ReportTemplateDetails = (props: AllProps): JSX.Element => {
  const [reportData, setReportData] = React.useState<ReportData | undefined>(undefined)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false)
  const [deleteTemplateResult, setDeleteTemplateResult] = React.useState(false)
  const [showUpdateConfirmation, setShowUpdateConfirmation] = React.useState(false)
  const [showUpdateStatus, setShowUpdateStatus] = React.useState(false)
  const [width, setWidth] = React.useState(0)
  const [newReportName, setNewReportName] = React.useState('')
  const [nameErrorInit, setNameErrorInit] = React.useState(false)
  const [newReportType, setNewReportType] = React.useState<Option | null>(null)
  const [reportTypes] = React.useState<Option[]>([{ label: 'CSV', value: '.csv' }, { value: '.cmep', label: 'CMEP' }])
  const [error, setError] = React.useState<boolean>(false)
  const [newReportContent, setNewReportContent] = React.useState<CreateColumnContent[]>([{ columnHeaderName: '', columnDisplayName: '', columnName: '', columnOrder: 0, columnId: '' }])

  const handleFetchFieldOptions = (): JSX.Element => {
    if (props.reportContentColumns.httpStatus === 200 && props.reportContentColumns !== undefined && props.reportContentColumns.reportContentColumn.length > 0) {
      return (
        <ModifyReportContentForm source={'ReportTemplateDetails'} reportName={newReportName} error={error} setError={setError} fieldOptions={props.reportContentColumns.reportContentColumn} sx={{ margin: '1rem' }} width={`calc(${width - 50}px - 1rem)`} reportContent={newReportContent.sort((a, b) => a.columnOrder - b.columnOrder)} setReportContent={setNewReportContent} />
      )
    } else if (props.reportContentColumns.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (props.reportContentColumns.httpStatus === 401) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.reportContentColumns.httpStatus === 403) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="accessForbidden" /></div>
      )
    } else if (props.reportContentColumns.httpStatus === 200 && props.reportContentColumns.reportContentColumn.length === 0) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="cannotFetch" retry={props.fetchReportContentColumns} /></div>
      )
    }
  }

  useEffect(() => {
    props.fetchReportContentColumns()
  }, [])

  useEffect(() => {
    setReportData(props.selectedTemplate)
    const deepCopyReportContent = _.cloneDeep(props.selectedTemplate !== undefined ? props.selectedTemplate.reportContent : [])
    setNewReportContent(deepCopyReportContent)
    props.setEditEnabled(false)
    setNewReportName(props.selectedTemplate !== undefined ? props.selectedTemplate.reportName : '')
    let selectedReportType: Option = reportTypes[0]
    if (props.selectedTemplate !== undefined) {
      selectedReportType = reportTypes.filter((type) => type.value === props.selectedTemplate?.reportType)[0]
    }
    setNewReportType({ label: selectedReportType.label, value: selectedReportType.value })
  }, [props.selectedTemplate])

  useEffect(() => {
    if (props.reportTemplateDetailsRef.current !== null) {
      setWidth(props.reportTemplateDetailsRef.current.clientWidth)
    }
  }, [props.reportTemplateDetailsRef])

  const handleDeleteReportTemplate = (): void => {
    if (reportData !== undefined) {
      props.deleteReportTemplate(reportData.reportTemplateId)
    }
  }

  const resetReportColumnContent = (): void => {
    setReportData(props.selectedTemplate)
    const deepCopyReportContent = _.cloneDeep(props.selectedTemplate !== undefined ? props.selectedTemplate.reportContent : [])
    setNewReportContent(deepCopyReportContent)
    setNewReportName(props.selectedTemplate !== undefined ? props.selectedTemplate.reportName : '')
    setNewReportType({ label: props.selectedTemplate !== undefined ? props.selectedTemplate.reportType : '', value: props.selectedTemplate !== undefined ? props.selectedTemplate.reportType : '' })
  }

  const handleUpdateReportTemplate = (): void => {
    const newReport = {
      reportName: newReportName,
      reportType: newReportType !== null ? newReportType.value : '',
      reportContent: newReportContent
    }
    props.updateReportTemplate(reportData !== undefined ? reportData.reportTemplateId : '', newReport)
  }

  const checkReportModified = (): boolean => {
    if (reportData !== undefined) {
      return (reportData.reportName !== newReportName || reportData.reportType !== newReportType?.value || !_.isEqual(reportData.reportContent, newReportContent))
    }
    return false
  }

  const isNameNotValid = (): boolean => {
    return (!isValidText(newReportName) || (newReportName.length > 60))
  }

  const handleReportSelect = (selectedValue: Option): void => {
    setNewReportType(selectedValue)
  }

  const handleDeleteReportTemplateWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.deleteReportTemplateState.httpStatus === 200) {
      return (
        <Modal buttonTypeNegative='hollow' title='Successfully Deleted' LabelPositive='Ok'
          onClickPositive={() => {
            setDeleteTemplateResult(false)
            props.handleFetchTemplateDetailsList()
          }}
          onClose={() => {
            setDeleteTemplateResult(false)
            props.handleFetchTemplateDetailsList()
          }}>
          <p style={{ paddingTop: '1.2em' }}>Report Template has been deleted successfully</p>
        </Modal>
      )
    } else if (props.deleteReportTemplateState.isLoading) {
      return (
        <Modal title='Deleting Report Template'>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal width={'500px'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setDeleteTemplateResult(false) }}
          onClose={() => { setDeleteTemplateResult(false) }} title='Deleting Report Template'
          onClickPositive={handleDeleteReportTemplate}
        >
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} customError='Unable to delete Report Template' /></div>
        </Modal>
      )
    }
  }

  const handleUpdateReportTemplateWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.updateReportTemplateState.httpStatus === 200) {
      return (
        <Modal buttonTypeNegative='hollow' title='Successfully Updated' LabelPositive='Ok'
          onClickPositive={() => {
            setShowUpdateStatus(false)
            props.handleFetchTemplateDetailsList()
          }}
          onClose={() => {
            setShowUpdateStatus(false)
            props.handleFetchTemplateDetailsList()
          }}>
          <p style={{ paddingTop: '1.2em' }}>Report Template has been modified successfully</p>
        </Modal>
      )
    } else if (props.updateReportTemplateState.isLoading) {
      return (
        <Modal title='Udpating Report Template'>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal width={'500px'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setShowUpdateStatus(false) }}
          onClose={() => { setShowUpdateStatus(false) }} title='Udpating Report Template'
          onClickPositive={handleUpdateReportTemplate}
        >
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} customError='Unable to Update Report Template' /></div>
        </Modal>
      )
    }
  }

  const checkForErrors = (): boolean => {
    return error || isNameNotValid() || newReportContent.some((report) => report.columnName === '' || report.columnHeaderName === '')
  }

  return (
    <div ref={props.reportTemplateDetailsRef} id='reportTemplateDetailsContainer' style={{ borderTopRightRadius: '10px', position: 'relative' }}>
      { props.selectedTemplate !== undefined
        ? <>
        <div>
          <div id='reportTemplateDetailsHeadingContainer'>
            <p id='reportTemplateDetailsHeading'>{reportData?.reportName}</p>
          </div>
          <div id='reportTemplatMinoreDetailsContainer'>
            <div className='report-details-info-container' data-testid='report-name-container'>
              <p id='report-name-title'>{ReportTemplateDetailsStrings.Report_Name}</p>
              {
                props.editEnabled
                  ? <>
                  <TextField
                    id='custom-textfield'
                    variant="outlined"
                    data-testid='report-name'
                    placeholder='Enter Name'
                    InputLabelProps={{
                      disabled: true
                    }}
                    value={newReportName}
                    onChange={(e) => {
                      setNameErrorInit(true)
                      setNewReportName(e.target.value)
                      if ((!isValidText(e.target.value) || (e.target.value.length > 60))) {
                        setError(true)
                      } else {
                        let temp = false
                        for (const rep of newReportContent) {
                          if (!isValidText(rep.columnHeaderName) || (rep.columnHeaderName.length > 60)) {
                            temp = true
                            break
                          }
                        }
                        setError(temp)
                      }
                    }}
                    size='small'
                    autoComplete='off'
                    className='custom-textfield'
                    sx={{
                      ...textStyle.root,
                      width: '100%'
                    }}
                  />
                  {(isNameNotValid() && nameErrorInit) && (
                    <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                      {ReportTemplateDetailsStrings.Enter_a_valid_name}
                    </FormHelperText>
                  )}
                </>
                  : <p className="report-info-title">{reportData?.reportName}</p>
              }
            </div>
            <div className='report-details-info-container' data-testid='report-type-container'>
              <p id='report-type-title'>{ReportTemplateDetailsStrings.Report_Type}</p>
              {
                props.editEnabled
                  ? <span style={{ fontSize: '14px' }}><GenXDropdown placeholder={ReportTemplateDetailsStrings.Select_Report_Type} value={newReportType} options={reportTypes} onSelect={handleReportSelect} /></span>
                  : <p className="report-info-title">{reportData !== undefined ? reportTypes.filter(repType => repType.value === reportData.reportType)[0].label : ''}</p>
              }
            </div>
          </div>
          {
            props.editEnabled
              ? <div style={{ marginBottom: '90px' }}>
                {
                  handleFetchFieldOptions()
                }
              </div>
              : <ReportContentsTable reportTemplateDetailsRef={props.reportTemplateDetailsRef} reportContent={reportData !== undefined ? reportData.reportContent : []} />
          }
        </div>
        <div style={{ backgroundColor: '#272727', padding: '0.6rem', display: 'flex', justifyContent: 'space-between', height: '72px', width: '100%', position: 'absolute', bottom: 0 }}>
          {
            props.editEnabled
              ? <>
            <Button type='hollow' onClick={() => {
              props.setEditEnabled(false)
              resetReportColumnContent()
            }}>{ReportTemplateDetailsStrings.Cancel}</Button>
          <Button primaryColor={(checkReportModified() && !checkForErrors()) ? undefined : '#B0B0B0'} disabled={(!checkReportModified() || checkForErrors())} onClick={() => { setShowUpdateConfirmation(true) }}>{ReportTemplateDetailsStrings.Update}</Button>
            </>
              : <>
          {
            props.selectedTemplate.editable === 'Y'
              ? (
                <>
                  <Button type='hollow' onClick={() => { setShowDeleteConfirmation(true) }}>{ReportTemplateDetailsStrings.Delete}</Button>
                  <Button onClick={() => { props.setEditEnabled(true) }}>{ReportTemplateDetailsStrings.Edit}</Button>
                </>
                )
              : <></>
          }
            </>
          }
        </div>
        {
          showDeleteConfirmation && <Modal title={ReportTemplateDetailsStrings.Delete_Report_Template} LabelNegative={ReportTemplateDetailsStrings.Cancel} LabelPositive={ReportTemplateDetailsStrings.Confirm}
            onClickNegative={() => { setShowDeleteConfirmation(false) }}
            onClickPositive={() => {
              setShowDeleteConfirmation(false)
              setDeleteTemplateResult(true)
              handleDeleteReportTemplate()
            }}
            buttonTypeNegative='hollow'
          >
            <p style={{ paddingTop: '1.2em', paddingBottom: '1.2em' }}>{ReportTemplateDetailsStrings.Do_you_want_to_delete_this_report_template}</p>
          </Modal>
        }
        {
          deleteTemplateResult && handleDeleteReportTemplateWithFailure()
        }
        {
          showUpdateConfirmation && <Modal title={'Update Report Template'} LabelNegative={ReportTemplateDetailsStrings.Cancel} LabelPositive={ReportTemplateDetailsStrings.Confirm}
            onClickNegative={() => { setShowUpdateConfirmation(false) }}
            onClickPositive={() => {
              setShowUpdateConfirmation(false)
              setShowUpdateStatus(true)
              handleUpdateReportTemplate()
            }}
            buttonTypeNegative='hollow'
          >
            <p style={{ paddingTop: '1.2em', paddingBottom: '1.2em' }}>Do you want to update Report Template ?</p>
          </Modal>
        }
        {
          showUpdateStatus && handleUpdateReportTemplateWithFailure()
        }
      </>
        : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><div style={{
          height: '15em',
          marginLeft: '40%',
          textAlign: 'center',
          width: '50em'
        }}><AuthError errorMessage="NoDataPresent" /></div></div>
      }
    </div>
  )
}

interface DispatchToProps {
  fetchReportContentColumns: () => void
  deleteReportTemplate: (reportTemplateId: string) => void
  updateReportTemplate: (templateId: string, reportTemplate: CreateReportData) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchReportContentColumns: () => dispatch(fetchReportContentColumns()),
  deleteReportTemplate: (reportTemplateId: string) => dispatch(deleteReportTemplate(reportTemplateId)),
  updateReportTemplate: (templateId: string, reportTemplate: CreateReportData) => dispatch(editReportTemplate(templateId, reportTemplate))
})

interface StateToProps {
  reportContentColumns: ReportContentColumnState
  deleteReportTemplateState: DeleteReportTemplateState
  updateReportTemplateState: EditReportTemplateState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  reportContentColumns: state.reportContentColumns,
  deleteReportTemplateState: state.deleteReportTemplateState,
  updateReportTemplateState: state.editReportTemplateState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = PropsFromRedux & ParentProps
export default connector(ReportTemplateDetails)
