import LanguageStrings from '../../../i18n/locales'
import React, { useEffect } from 'react'
import { type RootState } from '../../../store'
import { type ConnectedProps, connect } from 'react-redux'
import { createNewConfig, deleteConfig, fetchSingleConfig, updateConfig } from '../redux/actionCreators'
import { Card, CardContent, Grid, TextField } from '@mui/material'
import Modal from '../../../components/Modal'
import { type AllConfigData } from '../redux/allConfigSlice'
import { GenericDropdown, type Option } from '../../../components/GenericDropdown'
import BackButton from '../../../components/BackButton'
import { Button } from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { type CreateConfigurationState } from '../redux/addNewConfigSlice'
import { useAuth } from 'react-oidc-context'
import { type SingleConfigState } from '../redux/singleConfigSlice'
import { isValidIp, validateDescription, validateHour, validateMinute, validateName, validatePassword, validatePort, validatePublicKey, validateUrl, validateUsername } from './addConfig/fileValidation'

const AssetConfigurationStrings = LanguageStrings().AssetConfigurationStrings.components.AssetConfiguration

const textStyle = {
  root: {
    backgroundColor: '#272727',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      backgroundColor: '#404040'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

const AddNewConfig = (props: PropsFromRedux): JSX.Element => {
  const auth = useAuth()

  const [sessionConfigId, setSessionConfigId] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [button, setButton] = React.useState('')
  const [createConfigStatus, setCreateConfigStatus] = React.useState(false)
  const [updateConfigStatus, setUpdateConfigStatus] = React.useState(false)
  const [deleteButton, setDeleteButton] = React.useState(false)
  const [deletePopup, setDeletePopup] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const [fileFormatedSelected, setFileFormatedSelected] = React.useState(false)
  const [specVersionRender, setSpecVersionRender] = React.useState(false)
  const [name, setName] = React.useState('')

  const [inputFocusedVersion, setInputFocusedVersion] = React.useState(false)
  const [description, setDescription] = React.useState('')
  const [fileFormatOptions, setFileFormatOptions] = React.useState<Option[]>([])
  const [fileFormat, setFileFormat] = React.useState('')
  const [specVersionOptions, setSpecVersionOptions] = React.useState<Option[]>([])
  const [specVersion, setSpecVersion] = React.useState('')

  const [protocolSelected, setProtocolSelected] = React.useState(false)
  const [protocolOptions, setProtocolOptions] = React.useState<Option[]>([])
  const [protocol, setProtocol] = React.useState('')
  const [hostName, setHostName] = React.useState('')
  const [port, setPort] = React.useState('')
  const [publicKey, setPublicKey] = React.useState('')
  const [url, setUrl] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [frequencySelected, setFrequencySelected] = React.useState(false)
  const [frequencyOptions, setFrequencyOptions] = React.useState<Option[]>([])
  const [frequency, setFrequency] = React.useState('')
  const [minutesBetweenPublishing, setMinutesBetweenPublishing] = React.useState('')
  const [hourDayStart, setHourDayStart] = React.useState('')
  const [minuteDayStart, setMinuteDayStart] = React.useState('')
  const [minutesAfterHour, setMinutesAfterHour] = React.useState('')

  const navigate = useNavigate()

  const handleChooseFileFormat = (e: any): void => {
    setFileFormat(e.target.value)
    setFileFormatedSelected(true)
    if (e.target.value === 'CMEP') {
      setSpecVersionRender(true)
    } else {
      setSpecVersionRender(false)
    }
  }

  const handleChooseSpecVersion = (e: any): void => {
    setSpecVersion(e.target.value)
  }

  const handleChooseProtocol = (e: any): void => {
    setProtocol(e.target.value)
    setProtocolSelected(true)
  }

  const handleChooseFrequency = (e: any): void => {
    setFrequency(e.target.value)
    setFrequencySelected(true)
  }

  const setDropDownValues = (): void => {
    const formats: Option[] = []
    formats.push({ value: 'CMEP', label: 'CMEP' })
    formats.push({ value: 'CSV', label: 'CSV' })
    setFileFormatOptions(formats)

    const specVersions: Option[] = []
    specVersions.push({ value: '19970819', label: '19970819' })
    specVersions.push({ value: '20180216', label: '20180216' })
    setSpecVersionOptions(specVersions)

    const protocols: Option[] = []
    protocols.push({ value: 'SFTP', label: 'SFTP' })
    protocols.push({ value: 'HTTPS', label: 'HTTPS' })
    setProtocolOptions(protocols)

    const frequencys: Option[] = []
    // frequencys.push({ value: 'CONTINUOUS', label: 'CONTINUOUS' })
    frequencys.push({ value: 'MULTIPLE PER HOUR', label: 'MULTIPLE PER HOUR' })
    frequencys.push({ value: 'ONCE PER HOUR', label: 'ONCE PER HOUR' })
    frequencys.push({ value: 'ONCE PER DAY', label: 'ONCE PER DAY' })
    setFrequencyOptions(frequencys)
  }

  useEffect(() => {
    setSessionConfigId(props.configId)
    setDropDownValues()

    if (sessionConfigId === '') {
      setTitle('CREATE CONFIGURATION')
      setButton('Create')
    } else {
      props.fetchSingleConfig(sessionConfigId)
      setTitle('VIEW/UPDATE CONFIGURATION')
      setButton('Update')
      setDeleteButton(true)
      populateConfigData()
    }
  }, [])

  useEffect(() => {
    if (sessionConfigId === '') {
      setTitle('CREATE CONFIGURATION')
      setButton('Create')
    } else {
      props.fetchSingleConfig(sessionConfigId)
      setTitle('VIEW/UPDATE CONFIGURATION')
      setButton('Update')
      setDeleteButton(true)
      populateConfigData()
    }
  }, [sessionConfigId, props.configId])

  useEffect(() => {
    if (sessionConfigId !== '') {
      populateConfigData()
    }
  }, [props.singleConfigData])

  const handleInputFocus = (isFocused: boolean | ((prevState: boolean) => boolean)): void => {
    setInputFocusedVersion(isFocused)
  }

  const basicInfoSection = (): JSX.Element => {
    return (
      <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '15px', marginTop: '20px' }}>
        <h4 style={{ padding: 10 }}>{'CONFIGURATION SPECIFICATIONS'}</h4>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{AssetConfigurationStrings.Name}<sup style={{ color: '#EE3426' }}>*</sup></span>
            <TextField
              id= "standard-error-helper-text"
              variant="outlined"
              placeholder='Enter'
              InputLabelProps={{
                disabled: true
              }}
              size='small'
              className='custom-textfield'
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
              sx={{
                ...textStyle.root,
                width: '450px',
                border: (validateName(name)) ? '1px solid #EE3426' : 'none'
              }}
            />
            <div style={{ color: 'red', marginTop: '2px' }}>
              {(validateName(name) && !inputFocusedVersion) ? 'Name too long.' : ''}
              {(validateName(name) && inputFocusedVersion) ? 'Name too long.' : ''}
            </div>

            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Description'}<sup style={{ color: '#EE3426' }}>*</sup></span>
            <TextField
              variant="outlined"
              placeholder='Enter'
              InputLabelProps={{
                disabled: true
              }}
              size='small'
              className='custom-textfield'
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
              sx={{
                ...textStyle.root,
                width: '450px',
                border: (validateDescription(description)) ? '1px solid #EE3426' : 'none'
              }}
            />
            <div style={{ color: 'red', marginTop: '2px' }}>
              {(validateDescription(description) && !inputFocusedVersion) ? 'Description too long.' : ''}
              {(validateDescription(description) && inputFocusedVersion) ? 'Description too long.' : ''}
            </div>
            </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <div style={{ paddingTop: '10px' }}>
                  <GenericDropdown dataTestId='category' width={250} options={fileFormatOptions} value={fileFormat} onChange={handleChooseFileFormat} label={'File Format'} />
                </div>
              </Grid>
            {specVersionRender
              ? <Grid item xs={12} sm={6} md={4} lg={3}>
                  <div style={{ paddingTop: '10px' }}>
                  <GenericDropdown dataTestId='category' width={250} options={specVersionOptions} value={specVersion} onChange={handleChooseSpecVersion} label={'Spec Version'}/>
                  </div>
                </Grid>
              : <></>
            }

          </Grid>
        </CardContent>
      </Card>
    )
  }

  const protocolSection = (): JSX.Element => {
    return (
      <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '15px', marginTop: '20px' }}>
        <h4 style={{ padding: 10 }}>{'CONFIGURATION PROTOCOL'}</h4>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div style={{ paddingTop: '10px' }}>
                <GenericDropdown dataTestId='category' width={250} options={protocolOptions} value={protocol} onChange={handleChooseProtocol} label={'Protocol'} />
              </div>
            </Grid>
            { handleProtocolEntries() }
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const handleProtocolEntries = (): JSX.Element => {
    return (
          <>
          {protocolSelected
            ? (protocol === 'SFTP'
                ? <>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'SFTP IP Address'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                    <TextField
                      variant="outlined"
                      placeholder='Enter'
                      InputLabelProps={{
                        disabled: true
                      }}
                      size='small'
                      className='custom-textfield'
                      value={hostName}
                      onChange={(e) => {
                        setHostName(e.target.value)
                      }}
                      onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                      sx={{
                        ...textStyle.root,
                        width: '450px',
                        border: (!isValidIp(hostName)) ? '1px solid #EE3426' : 'none'
                      }}
                    />
                    <div style={{ color: 'red', marginTop: '2px' }}>
                      {(!isValidIp(hostName) && !inputFocusedVersion) ? 'Enter a valid IP.' : ''}
                      {(!isValidIp(hostName) && inputFocusedVersion) ? 'Enter a valid IP.' : ''}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'SFTP Port'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                    <TextField
                      variant="outlined"
                      placeholder='Enter'
                      InputLabelProps={{
                        disabled: true
                      }}
                      size='small'
                      className='custom-textfield'
                      value={port}
                      onChange={(e) => {
                        setPort(e.target.value)
                      }}
                      onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                      sx={{
                        ...textStyle.root,
                        width: '450px',
                        border: (validatePort(port)) ? '1px solid #EE3426' : 'none'
                      }}
                    />
                    <div style={{ color: 'red', marginTop: '2px' }}>
                      {(validatePort(port) && !inputFocusedVersion) ? 'Port range is 0 to 65535.' : ''}
                      {(validatePort(port) && inputFocusedVersion) ? 'Port range is 0 to 65535.' : ''}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'SFTP Public Key'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                  <TextField
                    variant="outlined"
                    placeholder='Enter'
                    InputLabelProps={{
                      disabled: true
                    }}
                    size='small'
                    className='custom-textfield'
                    value={publicKey}
                    onChange={(e) => {
                      setPublicKey(e.target.value)
                    }}
                    sx={{
                      ...textStyle.root,
                      width: '450px',
                      border: (validatePublicKey(publicKey)) ? '1px solid #EE3426' : 'none'
                    }}
                    onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                  />
                  <div style={{ color: 'red', marginTop: '2px' }}>
                      {(validatePublicKey(publicKey) && !inputFocusedVersion) ? 'Public Key too long.' : ''}
                      {(validatePublicKey(publicKey) && inputFocusedVersion) ? 'Public Key too long.' : ''}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Username'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                    <TextField
                      variant="outlined"
                      placeholder='Enter'
                      InputLabelProps={{
                        disabled: true
                      }}
                      size='small'
                      className='custom-textfield'
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value)
                      }}
                      sx={{
                        ...textStyle.root,
                        width: '450px',
                        border: (validateUsername(username)) ? '1px solid #EE3426' : 'none'
                      }}
                    />
                    <div style={{ color: 'red', marginTop: '2px' }}>
                      {(validateUsername(username) && !inputFocusedVersion) ? 'Username too long.' : ''}
                      {(validateUsername(username) && inputFocusedVersion) ? 'Username too long.' : ''}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Password'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                    <TextField
                      type="password"
                      variant="outlined"
                      placeholder='Enter'
                      InputLabelProps={{
                        disabled: true
                      }}
                      size='small'
                      className='custom-textfield'
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                      sx={{
                        ...textStyle.root,
                        width: '450px',
                        border: (validatePassword(password)) ? '1px solid #EE3426' : 'none'
                      }}
                    />
                    <div style={{ color: 'red', marginTop: '2px' }}>
                      {(validatePassword(password) && !inputFocusedVersion) ? 'Password too long.' : ''}
                      {(validatePassword(password) && inputFocusedVersion) ? 'Password too long.' : ''}
                    </div>
                  </Grid>
              </>
                : <><Grid item xs={12} sm={6} md={4} lg={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'URL'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                  <TextField
                    variant="outlined"
                    placeholder='Enter'
                    InputLabelProps={{
                      disabled: true
                    }}
                    size='small'
                    className='custom-textfield'
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value)
                    }}
                    onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                    sx={{
                      ...textStyle.root,
                      width: '450px',
                      border: (validateUrl(url)) ? '1px solid #EE3426' : 'none'
                    }}
                  />
                  <div style={{ color: 'red', marginTop: '2px' }}>
                    {(validateUrl(url) && !inputFocusedVersion) ? 'URL too long.' : ''}
                    {(validateUrl(url) && inputFocusedVersion) ? 'URL too long.' : ''}
                  </div>
                  </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Username'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                  <TextField
                    variant="outlined"
                    placeholder='Enter'
                    InputLabelProps={{
                      disabled: true
                    }}
                    size='small'
                    className='custom-textfield'
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                    onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                    sx={{
                      ...textStyle.root,
                      width: '450px',
                      border: (validateUsername(username)) ? '1px solid #EE3426' : 'none'
                    }}
                  />
                  <div style={{ color: 'red', marginTop: '2px' }}>
                    {(validateUsername(username) && !inputFocusedVersion) ? 'Username too long.' : ''}
                    {(validateUsername(username) && inputFocusedVersion) ? 'Username too long.' : ''}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Password'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                  <TextField
                    type="password"
                    variant="outlined"
                    placeholder='Enter'
                    InputLabelProps={{
                      disabled: true
                    }}
                    size='small'
                    className='custom-textfield'
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                    onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                    sx={{
                      ...textStyle.root,
                      width: '450px',
                      border: (validatePassword(password)) ? '1px solid #EE3426' : 'none'
                    }}
                  />
                  <div style={{ color: 'red', marginTop: '2px' }}>
                    {(validatePassword(password) && !inputFocusedVersion) ? 'Password too long.' : ''}
                    {(validatePassword(password) && inputFocusedVersion) ? 'Password too long.' : ''}
                  </div>
                </Grid></>)

            : <div></div>}

          </>
    )
  }

  const frequencySection = (): JSX.Element => {
    return (
      <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '15px', marginTop: '20px' }}>
        <h4 style={{ padding: 10 }}>{'CONFIGURATION FREQUENCY'}</h4>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div style={{ paddingTop: '10px' }}>
                <GenericDropdown dataTestId='category' width={250} options={frequencyOptions} value={frequency} onChange={handleChooseFrequency} label={'Frequency Type'} />
              </div>
            </Grid>
            {handleFrequencyEntries()}
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const handleFrequencyEntries = (): JSX.Element => {
    return (
          <>
          {frequencySelected
            ? (frequency === 'ONCE PER DAY'
                ? <>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Hour of day to start'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                    <TextField
                      variant="outlined"
                      placeholder='Enter'
                      InputLabelProps={{
                        disabled: true
                      }}
                      size='small'
                      className='custom-textfield'
                      value={hourDayStart}
                      onChange={(e) => {
                        setHourDayStart(e.target.value)
                      }}
                      onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                      sx={{
                        ...textStyle.root,
                        width: '450px',
                        border: (validateHour(hourDayStart)) ? '1px solid #EE3426' : 'none'
                      }}
                    />
                    <div style={{ color: 'red', marginTop: '2px' }}>
                      {(validateHour(hourDayStart) && !inputFocusedVersion) ? 'Hour range 0-23.' : ''}
                      {(validateHour(hourDayStart) && inputFocusedVersion) ? 'Hour range 0-23.' : ''}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Minute of day to start'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                    <TextField
                      variant="outlined"
                      placeholder='Enter'
                      InputLabelProps={{
                        disabled: true
                      }}
                      size='small'
                      className='custom-textfield'
                      value={minuteDayStart}
                      onChange={(e) => {
                        setMinuteDayStart(e.target.value)
                      }}
                      onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                      sx={{
                        ...textStyle.root,
                        width: '450px',
                        border: (validateMinute(minuteDayStart)) ? '1px solid #EE3426' : 'none'
                      }}
                    />
                    <div style={{ color: 'red', marginTop: '2px' }}>
                      {(validateMinute(minuteDayStart) && !inputFocusedVersion) ? 'Minute range 0-59.' : ''}
                      {(validateMinute(minuteDayStart) && inputFocusedVersion) ? 'Minute range 0-59.' : ''}
                    </div>
                  </Grid>
              </>
                : (frequency === 'MULTIPLE PER HOUR'
                    ? <><Grid item xs={12} sm={6} md={4} lg={3}>
                        <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Minutes between publishing'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                        <TextField
                          variant="outlined"
                          placeholder='Enter'
                          InputLabelProps={{
                            disabled: true
                          }}
                          size='small'
                          className='custom-textfield'
                          value={minutesBetweenPublishing}
                          onChange={(e) => {
                            setMinutesBetweenPublishing(e.target.value)
                          }}
                          onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                          sx={{
                            ...textStyle.root,
                            width: '450px',
                            border: (validateMinute(minutesBetweenPublishing)) ? '1px solid #EE3426' : 'none'
                          }}
                        />
                        <div style={{ color: 'red', marginTop: '2px' }}>
                          {(validateMinute(minutesBetweenPublishing) && !inputFocusedVersion) ? 'Minute range 0-59.' : ''}
                          {(validateMinute(minutesBetweenPublishing) && inputFocusedVersion) ? 'Minute range 0-59.' : ''}
                        </div>
                        </Grid></>
                    : <><Grid item xs={12} sm={6} md={4} lg={3}>
                          <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{'Minutes after hour'}<sup style={{ color: '#EE3426' }}>*</sup></span>
                          <TextField
                            variant="outlined"
                            placeholder='Enter'
                            InputLabelProps={{
                              disabled: true
                            }}
                            size='small'
                            className='custom-textfield'
                            value={minutesAfterHour}
                            onChange={(e) => {
                              setMinutesAfterHour(e.target.value)
                            }}
                            onFocus={() => { handleInputFocus(true) }} onBlur={() => { handleInputFocus(false) }}
                            sx={{
                              ...textStyle.root,
                              width: '450px',
                              border: (validateMinute(minutesAfterHour)) ? '1px solid #EE3426' : 'none'
                            }}
                          />
                          <div style={{ color: 'red', marginTop: '2px' }}>
                            {(validateMinute(minutesAfterHour) && !inputFocusedVersion) ? 'Minute range 0-59.' : ''}
                            {(validateMinute(minutesAfterHour) && inputFocusedVersion) ? 'Minute range 0-59.' : ''}
                          </div>
                        </Grid></>))
            : <div></div>}
          </>
    )
  }

  const checkForIncompleteForm = (): boolean => {
    const dropDowns = fileFormat === '' || protocol === '' || frequency === ''
    let basicInfo = false

    if (fileFormat === 'CMEP') {
      basicInfo = name === '' || description === '' || specVersion === '' || validateName(name) || validateDescription(description)
    } else if (fileFormat === 'CSV') {
      basicInfo = name === '' || description === '' || validateName(name) || validateDescription(description)
    }

    let protocolInfo = false
    if (protocol === 'SFTP') {
      protocolInfo = hostName === '' || port === '' || publicKey === '' || username === '' || password === '' || !isValidIp(hostName) || validatePort(port) || validatePublicKey(publicKey) || validateUsername(username) || validatePassword(password)
    } else if (protocol === 'HTTPS') {
      protocolInfo = url === '' || username === '' || password === '' || validateUrl(url) || validateUsername(username) || validatePassword(password)
    }

    let frequencyInfo = false
    if (frequency === 'MULTIPLE PER HOUR') {
      frequencyInfo = minutesBetweenPublishing === '' || validateMinute(minutesBetweenPublishing)
    } else if (frequency === 'ONCE PER HOUR') {
      frequencyInfo = minutesAfterHour === '' || validateMinute(minutesAfterHour)
    } else if (frequency === 'ONCE PER DAY') {
      frequencyInfo = hourDayStart === '' || minuteDayStart === '' || validateMinute(minuteDayStart) || validateHour(hourDayStart)
    }

    return dropDowns || basicInfo || protocolInfo || frequencyInfo
  }

  const handleFrequencyState = (input: string): number => {
    const frequencyType = ['CONTINUOUS', 'MULTIPLE PER HOUR', 'ONCE PER HOUR', 'ONCE PER DAY']
    return frequencyType.indexOf(input)
  }

  const saveConfig = (): void => {
    const currentDate = new Date()
    const year = currentDate.getUTCFullYear()
    const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getUTCDate().toString().padStart(2, '0')
    const hours = currentDate.getUTCHours().toString().padStart(2, '0')
    const minutes = currentDate.getUTCMinutes().toString().padStart(2, '0')
    const seconds = currentDate.getUTCSeconds().toString().padStart(2, '0')
    const milliseconds = currentDate.getUTCMilliseconds().toString().padStart(3, '0')
    const nanoseconds = '215'
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${nanoseconds}Z`

    props.createNewConfig(
      {
        description,
        spec_version: specVersion,
        name,
        public_key: '',
        file_created: formattedDate,
        encryption_enabled_state: false,
        protocol,
        sftp_host_name: hostName,
        url,
        username,
        password,
        sftp_port: ((port === '') ? 0 : parseInt(port)),
        sftp_public_key: publicKey,
        sftp_encryption_string: 'ssh-rsa',
        tenant_id: auth.user?.profile.customer as string,
        file_format: fileFormat,
        enabled: true,
        minutes_between_publishing: ((minutesBetweenPublishing === '') ? 0 : parseInt(minutesBetweenPublishing)),
        hour_of_day_to_start: ((hourDayStart === '') ? 0 : parseInt(hourDayStart)),
        minute_of_day_to_start: ((minuteDayStart === '') ? 0 : parseInt(minuteDayStart)),
        minutes_after_hour: ((minutesAfterHour === '') ? 0 : parseInt(minutesAfterHour)),
        frequency_type: handleFrequencyState(frequency)
      })
  }

  const updateConfig = (): void => {
    const currentDate = new Date()
    const year = currentDate.getUTCFullYear()
    const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getUTCDate().toString().padStart(2, '0')
    const hours = currentDate.getUTCHours().toString().padStart(2, '0')
    const minutes = currentDate.getUTCMinutes().toString().padStart(2, '0')
    const seconds = currentDate.getUTCSeconds().toString().padStart(2, '0')
    const milliseconds = currentDate.getUTCMilliseconds().toString().padStart(3, '0')
    const nanoseconds = '215'
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${nanoseconds}Z`

    // const currentEnabled = setEnabledFunction()

    props.updateConfig(
      props.configId,
      {
        description,
        spec_version: specVersion,
        name,
        public_key: '',
        file_created: formattedDate,
        encryption_enabled_state: false,
        protocol,
        sftp_host_name: hostName,
        url,
        username,
        password,
        sftp_port: ((port === '') ? 0 : parseInt(port)),
        sftp_public_key: publicKey,
        sftp_encryption_string: 'ssh-rsa',
        tenant_id: auth.user?.profile.customer as string,
        file_format: fileFormat,
        enabled: true,
        minutes_between_publishing: ((minutesBetweenPublishing === '') ? 0 : parseInt(minutesBetweenPublishing)),
        hour_of_day_to_start: ((hourDayStart === '') ? 0 : parseInt(hourDayStart)),
        minute_of_day_to_start: ((minuteDayStart === '') ? 0 : parseInt(minuteDayStart)),
        minutes_after_hour: ((minutesAfterHour === '') ? 0 : parseInt(minutesAfterHour)),
        frequency_type: handleFrequencyState(frequency)
      })
  }

  const buttonAction = (): void => {
    if (sessionConfigId === '') {
      saveConfig()
      setCreateConfigStatus(true)
    } else {
      updateConfig()
      setUpdateConfigStatus(true)
    }
  }

  const handleCreatePopup = (): JSX.Element => {
    return (
      <Modal buttonTypeNegative='hollow' title='Successfully created' LabelPositive='Ok' onClickPositive={() => { navigate('/datapub') }}
        onClose={() => { navigate('/datapub') }}>
        <p style={{ paddingTop: '1.2em' }}>{'Configuration has been created successfully'}</p>
      </Modal>
    )
  }

  const handleDeletedPopup = (): JSX.Element => {
    return (
      <Modal buttonTypeNegative='hollow' title='Successfully deleted' LabelPositive='Ok' onClickPositive={() => { navigate('/datapub') }}
        onClose={() => { navigate('/datapub') }}>
        <p style={{ paddingTop: '1.2em' }}>{'Configuration has been deleted successfully'}</p>
      </Modal>
    )
  }

  const handleUpdatePopup = (): JSX.Element => {
    return (
      <Modal buttonTypeNegative='hollow' title='Successfully updated' LabelPositive='Ok' onClickPositive={() => { navigate('/datapub') }}
        onClose={() => { navigate('/datapub') }}>
        <p style={{ paddingTop: '1.2em' }}>{'Configuration has been updated successfully'}</p>
      </Modal>
    )
  }

  const confirmClose = (): void => {
    setDeletePopup(false)
  }

  const deleteConfig = (): JSX.Element => {
    return (
      <>
        <Modal buttonTypeNegative='hollow' title='Delete Configuration' LabelNegative='Cancel' LabelPositive='Delete' onClickPositive={() => {
          props.deleteConfig(props.configId)
          setDeleted(true)
          setDeletePopup(false)
        }} onClose={confirmClose} onClickNegative={confirmClose}>
          <p style={{ paddingTop: '1.2em' }}>{'Are you sure you want to delete this config'}</p>
        </Modal>
      </>
    )
  }

  const renderDeleteButton = (): JSX.Element => {
    return (
      <>
        <Button id='top-right-btn-delete' sx={{ margin: 0 }} width='fit-content' onClick={() => {
          setDeletePopup(true)
        }}>DELETE</Button>
        {deletePopup ? deleteConfig() : <></>}
        {deleted ? handleDeletedPopup() : <></>}
      </>
    )
  }

  const populateConfigData = (): void => {
    if (props.singleConfigData.configData !== null && props.singleConfigData.configData !== undefined) {
      setDescription(props.singleConfigData.configData?.description)
      setSpecVersion(props.singleConfigData.configData?.spec_version)
      setName(props.singleConfigData.configData?.name)
      setFileFormat(props.singleConfigData.configData?.file_format)
      setFileFormatedSelected(true)
      if (props.singleConfigData.configData?.file_format === 'CMEP') {
        setSpecVersionRender(true)
      } else {
        setSpecVersionRender(false)
      }

      setHostName(props.singleConfigData.configData?.sftp_host_name)
      setPort(props.singleConfigData.configData?.sftp_port.toString())
      setPublicKey(props.singleConfigData.configData?.sftp_public_key)
      setUsername(props.singleConfigData.configData?.username)
      setPassword(props.singleConfigData.configData?.password)
      setUrl(props.singleConfigData.configData?.url)
      setProtocol(props.singleConfigData.configData?.protocol)
      setProtocolSelected(true)

      setMinutesBetweenPublishing(props.singleConfigData.configData?.minutes_between_publishing.toString())
      setHourDayStart(props.singleConfigData.configData?.hour_of_day_to_start.toString())
      setMinuteDayStart(props.singleConfigData.configData?.minute_of_day_to_start.toString())
      setMinutesAfterHour(props.singleConfigData.configData?.minutes_after_hour.toString())
      const incomingFrequency = props.singleConfigData.configData?.frequency_type
      const modifiedFrequency = incomingFrequency.replace(/_/g, ' ')
      setFrequency(modifiedFrequency)
      setFrequencySelected(true)
    }
  }

  return (
    <div>
      <div className="topUtilityBar">
        <BackButton />
        <span id="componentTitle">{title}</span>
        <span id='top-right-ami-utilities'>
          <span id='top-right-btn-span'>
            {deleteButton ? renderDeleteButton() : <></>}
          </span>
        </span>
      </div>
      <div style={{ marginTop: '20px' }}>
        {basicInfoSection()}
          {fileFormatedSelected
            ? (
              <>
                {protocolSection()}
                {frequencySection()}
              </>
              )
            : <></>}
      </div>
      <div style={{ width: '100%', height: '80px', backgroundColor: '#272727', margin: '50px 0 10px 0', borderRadius: 10 }}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 1em' }}>
          <Button type='hollow' onClick={() => { navigate('/datapub') }}>Cancel</Button>
          <Button primaryColor={checkForIncompleteForm() ? '#B0B0B0' : undefined} onClick={() => { buttonAction() }} disabled={ checkForIncompleteForm() }>{button}</Button>
        </div>
      </div>
      {createConfigStatus ? handleCreatePopup() : <></>}
      {updateConfigStatus ? handleUpdatePopup() : <></>}
    </div>
  )
}

interface DispatchToProps {
  createNewConfig: (configTemplate: AllConfigData) => void
  updateConfig: (configId: string, configTemplate: AllConfigData) => void
  fetchSingleConfig: (configId: string) => void
  deleteConfig: (configId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  createNewConfig: (configTemplate: AllConfigData) => dispatch(createNewConfig(configTemplate)),
  fetchSingleConfig: (configId: string) => dispatch(fetchSingleConfig(configId)),
  updateConfig: (configId: string, configTemplate: AllConfigData) => dispatch(updateConfig(configId, configTemplate)),
  deleteConfig: (configId: string) => dispatch(deleteConfig(configId))
})
interface StateToProps {
  createDatapubConfig: CreateConfigurationState
  singleConfigData: SingleConfigState
  configId: string
}

const mapStateToProps = (state: RootState): StateToProps => ({
  createDatapubConfig: state.createDatapubConfig,
  singleConfigData: state.singleConfigData,
  configId: state.configId
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddNewConfig)
