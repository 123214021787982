import { z } from 'zod'

export const ZAssetGroup = z.object({
  group_id: z.string(),
  group_name: z.string(),
  category_name: z.string(),
  asset_type_name: z.string(),
  no_of_assets: z.number(),
  description: z.string().nullable(),
  config_status: z.string().nullable(),
  firmware_status: z.string().nullable()
})

export type AssetGroup = z.infer<typeof ZAssetGroup>

export interface SelectAssetList {
  asset_id: string
  serial_number: string
  asset_type: string
  template_name: string[] | null
  state: string | null
  firmware_version: string | null
  tag: string[]
}

export interface CreateGroupPostRequest {
  group_name: string
  description: string
  type: string
  category_type_id: string
  asset_type_id: string
  assets: string[]
}

export const Ztag = z.array(z.string())

export const ZAssetForGroupObj = z.object(
  {
    asset_id: z.string(),
    serial_number: z.string(),
    firmware_version: z.string(),
    asset_type: z.string(),
    template_name: z.array(z.string()).nullable(),
    state: z.string().nullable(),
    tag: Ztag
  })
export interface Asset {
  id: string
  serialNumber: string
  assetType: string
  assetConfiguration: string
  tag: string
  state: string
}

export const ZAssetGroupDetails = z.object({
  group_id: z.string(),
  group_name: z.string(),
  status: z.string().nullable(),
  description: z.string(),
  group_type: z.string(),
  asset_type_name: z.string(),
  category_name: z.string(),
  no_of_assets: z.number(),
  asset_type_id: z.string()
})

export type AssetGroupDetails = z.infer<typeof ZAssetGroupDetails>

export const ZGroupsAssetObj = z.object(
  {
    asset_id: z.string(),
    serial_number: z.string(),
    firmware_version: z.string().optional(),
    asset_type: z.string(),
    template_name: z.array(z.string()).nullable(),
    state: z.string().nullable(),
    tag: Ztag
  })

export const ZAssetForGroup = z.object({
  total_count: z.number(),
  group_details: z.array(ZAssetForGroupObj)
})

export type AssetForGroup = z.infer<typeof ZAssetForGroup>
export type AssetForGroupObj = z.infer<typeof ZAssetForGroupObj>
export type Tag = z.infer<typeof Ztag>
export const ZGroupsAsset = z.object({
  total_count: z.number(),
  group_details: z.array(ZGroupsAssetObj)
})

export type GroupsAsset = z.infer<typeof ZGroupsAsset>
export type GroupsAssetObj = z.infer<typeof ZGroupsAssetObj>

export const ZUpdateGroupRequest = z.object({
  description: z.string(),
  assign_assets: z.string().array(),
  un_assign_assets: z.string().array()
})

export type UpdateGroupRequest = z.infer<typeof ZUpdateGroupRequest>
