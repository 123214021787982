import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DailyKpi } from '../services/dataCompletenessService'
// import * as ChartOptions from '../../asset-details/components/ChartOptions'

// What makes up this slice
export interface kpiDailyState {
  isLoading: boolean
  loadingError: string | null
  kpiDailyData: DailyKpi[]
  httpStatus: number | null
}

// Initial state of the slice
const initialState: kpiDailyState = {
  isLoading: false,
  loadingError: null,
  kpiDailyData: [],
  httpStatus: null
}

// Slice to return from action
export const kpiDailySlice = createSlice({
  name: 'kpiDailyMap',
  initialState,
  reducers: {
    kpiDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        kpiDailyData: [],
        httpStatus: 0
      }
    },
    kpiDataUpdateAction: (state, action: PayloadAction<{ kpiData: DailyKpi[], httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        kpiDailyData: action.payload.kpiData,
        httpStatus: action.payload.httpStatus
      }
    },
    kpiDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: action.payload.errorMessage,
        kpiDailyData: [],
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

// Actions to export
export const {
  kpiDataLoadingAction,
  kpiDataUpdateAction,
  kpiDataErrorAction
} = kpiDailySlice.actions

export default kpiDailySlice.reducer
