import React, { useState } from 'react'
import { fetchAssetAttribute, fetchAssetAttrParameter } from '../../redux/actionCreator'
import { type AssetAttrParameterState } from '../../redux/assetAttrParameterSlice'
import { type AssetByIdState } from '../../redux/assetByIdSlice'
import { type AssetAttributeState } from '../../redux/assetAttributeSlice'
import { type RootState } from '../../../../store'
import { connect, type ConnectedProps } from 'react-redux'
import TablePagination from '../../../../components/TablePagination'

interface AssetAttrParameterProps {
  configurationId: string
  page: number
  rowsPerPage: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const AssetAttrParameter = (props: AssetAttrParameterProps & AllProps): JSX.Element => {
  const [title] = useState(props.assetAttribute.assetAttribute?.find(attr => attr.configurationId === props.configurationId)?.configurationCategory)
  const { assetAttrParameterData, page, rowsPerPage } = props
  const totalCount = assetAttrParameterData.assetAttrParameter?.total_count ?? 0

  // Calculate the data slice for the current page
  const parameters = assetAttrParameterData.assetAttrParameter?.parameters ?? []

  return (
    <div style={{ border: '0.7px solid #101010', margin: '0px', borderRadius: '10px', fontSize: '12px', color: '#F0F0F0' }}>
      <div style={{ color: '#F0F0F0', padding: '10px 1em', backgroundColor: '#313030', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
        {title ?? ''}
      </div>
      <table style={{ width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ color: '#F0F0F0', padding: '10px', borderBottom: '2px solid #202020', textAlign: 'left' }}>Parameter</th>
            <th style={{ color: '#F0F0F0', padding: '10px', borderBottom: '2px solid #202020', borderLeft: '2px solid #202020', textAlign: 'left' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {parameters.map((param, index) => (
            <tr key={index}>
              <td style={{ color: '#F0F0F0', padding: '10px', borderBottom: '1px solid #202020' }}>
                {param.parameter}
              </td>
              <td style={{ color: '#F0F0F0', padding: '10px', borderBottom: '1px solid #202020' }}>
                {param.value}
              </td>
            </tr>
          ))}
          {/* No data available fallback */}
          {parameters.length === 0 && (
            <tr>
              <td colSpan={2} style={{ color: '#f0f0f0', textAlign: 'center', padding: '10px' }}>
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Render pagination if total_count > 10 */}
      {totalCount > 10 && (
        <TablePagination
          count={totalCount}
          page={page}
          setPage={props.setPage}
          rowsPerPage={rowsPerPage}
          siblingCount={1}
          boundaryCount={1}
        />
      )}
    </div>
  )
}

interface DispatchToProps {
  fetchAssetAttrParameter: (assetId: string, configurationId: string, page: number, size: number) => void
  fetchAssetAttribute: (assetId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetAttrParameter: (assetId: string, configurationId: string, page: number, size: number) =>
    dispatch(fetchAssetAttrParameter(assetId, configurationId, page, size)),
  fetchAssetAttribute: (assetId: string) => dispatch(fetchAssetAttribute(assetId))
})

interface StateToProps {
  assetAttrParameterData: AssetAttrParameterState
  assetData: AssetByIdState
  assetAttribute: AssetAttributeState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetAttrParameterData: state.assetAttrParameter,
  assetData: state.assetById,
  assetAttribute: state.assetAttributes
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = PropsFromRedux

export default connector(AssetAttrParameter)
