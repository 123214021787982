import interceptedAxios from '../../../utils/interceptedAxios'
import { type DonutKpiDataObject, ZDonutKpiApiResponse } from '../types'

export async function getDonutKpiData (
  kpiType: string,
  service: string,
  onSuccess: (dataObj: DonutKpiDataObject, httpStatus: number) => any,
  onFailure: (httpStatus: number) => any
): Promise<void> {
  const url = `/${service}/api/v1/udp/liveData/${kpiType}`

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZDonutKpiApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== undefined && parseResponse.data.data.liveData.length > 0) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(200)
        }
      } else {
        onFailure(response.status)
      }
    })
    .catch((error) => {
      onFailure(error.status)
    })
}
