/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/brace-style */
/* istanbul ignore file */
import { Box, Breadcrumbs, CircularProgress, FormHelperText, Grid, Link, Modal, TextField, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import { Button } from '../../../components/Button'
import Water_Merlin from '../../../assets/devices/WaterMerlinNBOT.svg'
import CloseIcon from '@mui/icons-material/Close'
import LanguageStrings from '../../../i18n/locales'
import '../../device-details/components/EditAsset.css'
import DeviceUtil from '../../../utils/DeviceUtil'
import { useSelector, connect } from 'react-redux'
import { type ConnectedProps } from 'react-redux'
import { type RootState } from '../../../store'
import Q600 from '../../../assets/devices/Q600.svg'
import NXS from '../../../assets/devices/NXCM_AC250.svg'
import Eletric from '../../../assets/devices/A4R.svg'
import DefaultImage from '../../../assets/devices/No-Image-Placeholder.svg'
import HBG3 from '../../../assets/devices/HBG3.svg'
import { editAssetSummaryInfo, getAllTagsByIDInfo } from '../redux/actionCreator'
import { type editSummaryReq } from '../services/editAssetSummaryService'
import { type EditAssetSummaryInfo } from '../redux/editAssetSummaryInfoSlice'
import { useAuth } from 'react-oidc-context'
import { isValidName, isValidIp, isValidPort } from '../../../utils/validator'
import { GenericDropdown, type Option } from '../../../components/GenericDropdown'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { styled } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { type Dayjs } from 'dayjs'
import { type assetStateReq } from '../../ami-meter-data-collection/services/updateAssetstate'
import { updateAssetStateInfo } from '../../ami-meter-data-collection/redux/actionCreators'
import { type UpdateAssetStateInfo } from '../../ami-meter-data-collection/redux/updateAssetStateSlice'
import AuthError from '../../../components/ErrorComponents/AuthError'
import GenericModal from '../../../components/Modal'
import Y290 from '../../../assets/devices/Y290.png'
import TSherpaBridge from '../../../assets/devices/TSherpaBridge.png'
const EditAssetSummaryStrings = LanguageStrings().AssetConfigurationStrings.components.EditAssetSummaryDetailsComponents

const assetValuesStyles = {
  display: 'block',
  margin: '30px 0px'
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4,
  borderRadius: '5px'
}

function getDeviceImage (device: any): string {
  if (device != null) {
    if (DeviceUtil.getDeviceTypeByName(device)?.isGas === true) {
      return NXS
    } else if (DeviceUtil.getDeviceTypeByName(device)?.isElectric === true) {
      return Eletric
    } else if (DeviceUtil.getDeviceTypeByName(device)?.isWater === true) {
      if (DeviceUtil.getDeviceTypeByName(device)?.isQ600 === true) {
        return Q600
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isHBG3 === true) {
        return HBG3
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isY290 === true) {
        return Y290
      } else if (DeviceUtil.getDeviceTypeByName(device)?.isTSherpaBridge === true) {
        return TSherpaBridge
      }
      return Water_Merlin
    }
  }
  return DefaultImage
}

const EditAssetSummary = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [showEditSummaryModal, setshowEditSummaryModal] = React.useState(false)
  const AssetSummaryData = useSelector((state: RootState) => state.assetById?.assetData)
  const tagUpdateHttpStatus = useSelector((state: RootState) => state.EditAssetSummaryInfoSlice.httpStatus)
  const assetStateTypeData = useSelector((state: RootState) => state.AssetStatesTypeData?.AssetStatesTypeData)
  const AllTagsData: any = useSelector((state: RootState) => state.getAllTags?.tags)
  const AllTagsDataLoading: any = useSelector((state: RootState) => state.getAllTags?.isLoading)
  const [StateSucessModal, setStateSucessModal] = React.useState<boolean>(false)
  const [showAlertStateUpdate, setShowAlertStateUpdate] = React.useState(false)
  const [reqError, setReqError] = React.useState<boolean>(false)
  const [AssetStateTypeOptions, setAssetStateTypeOptions] = React.useState<Option[]>([])
  const [ip, setIp] = React.useState(AssetSummaryData?.ip_address ?? '')
  const [port, setPort] = React.useState(AssetSummaryData?.port ?? '')
  const [ipError, setIpError] = React.useState('')
  const [portError, setPortError] = React.useState('')
  const auth = useAuth()
  const defaultDate = dayjs(new Date())
  const [isDateChanged, setDateChanged] = React.useState(false)
  const [installationStateDate, setInstallationStateDate] = React.useState<Dayjs | null | undefined>(AssetSummaryData?.install_date ? dayjs(AssetSummaryData?.install_date) : defaultDate)
  const [DisplayTag, setDisplayTag] = React.useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isStateChanged, setIsStateChanged] = React.useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  let paramAssetId = sessionStorage.getItem('paramAssetId')
  if (paramAssetId === null) {
    paramAssetId = ''
  }

  function getDropdownValue () {
    const val = AssetStateTypeOptions.filter(obj => summaryState.state === obj.label)
    if (val.length > 0) {
      return val[0].value
    }
    return ''
  }

  function useOutsideAlerter (ref: any) {
    React.useEffect(() => {
      function handleClickOutside (event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDisplayTag(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const oldintialState = {
    tag: [{ name: AssetSummaryData?.tag[0]?.name ? AssetSummaryData?.tag[0]?.name : '' }],
    state: AssetSummaryData?.asset_state,
    installDate: installationStateDate,
    ip: {
      ip_address: AssetSummaryData?.ip_address ? AssetSummaryData?.ip_address : '',
      port: AssetSummaryData?.port ? AssetSummaryData?.port : ''
    },
    confirmedStateUpdate: false
  }

  const intialState: editSummaryReq = {
    tag: [{ name: AssetSummaryData?.tag[0]?.name ? AssetSummaryData?.tag[0]?.name : '' }],
    state: AssetSummaryData?.asset_state ? AssetSummaryData?.asset_state : '',
    installDate: installationStateDate ?? defaultDate,
    ip: {
      ip_address: ip ?? '',
      port: port ?? ''
    },
    confirmedStateUpdate: false
  }

  const WhiteDatePicker = styled(DatePicker)({
    '& input': {
      color: '#b8b6b6',
      padding: '10px 14px'
    }
  })

  const CustomLocalizationProviderWrapper = styled('div')({
    backgroundColor: '#5c5b5bdd',
    borderRadius: '4px'
  })

  React.useEffect(() => {
    props.getAllTagsByIDInfo('')
  }, [])

  React.useEffect(() => {
    if (assetStateTypeData !== null && assetStateTypeData?.length > 0) {
      const assetStateTypes: Option[] = []
      assetStateTypeData?.forEach((assetState: any) => {
        assetStateTypes.push({
          value: assetState.asset_state_type_id,
          label: assetState.asset_state_type_name
        })
      })
      setAssetStateTypeOptions(assetStateTypes)
    }
  }, [assetStateTypeData])

  const assetId = paramAssetId ?? ''

  const [summaryState, setSummaryState] = React.useState<editSummaryReq>(intialState)

  const closeAddModal = () => {
    setshowEditSummaryModal(false)
  }

  const handleSelecttags = (e: any) => {
    setDisplayTag(true)
    props.getAllTagsByIDInfo(e.target.value)
    setSummaryState({ ...summaryState, tag: [{ name: e.target.value }] })
  }

  const handleIpChange = (e: any): void => {
    if (e.target.value === '') {
      setIpError('')
      setIp('')
    } else if (!isValidIp(e.target.value)) {
      setIpError('Invalid IP')
      setIp(e.target.value)
    } else {
      setIpError('')
      setIp(e.target.value)
    }
    setSummaryState({ ...summaryState, ip: { ip_address: e.target.value, port } })
  }

  const handlePortChange = (e: any): void => {
    if (e.target.value === '') {
      setPortError('')
      setPort('')
    } else if (!isValidPort(e.target.value)) {
      setPortError('Invalid Port')
      setPort(e.target.value)
    } else {
      setPortError('')
      setPort(e.target.value)
    }
    setSummaryState({ ...summaryState, ip: { ip_address: ip, port: e.target.value } })
  }

  const handleConfirmUpdate = () => {
    props.editAssetSummaryInfo(summaryState, assetId ?? '', auth.user?.profile.customer as string)
    setshowEditSummaryModal(false)
    setStateSucessModal(true)
  }

  const closeStateChangeModal = () => {
    setStateSucessModal(false)
    navigate('/devicedetails')
  }

  const handleChooseState = (name: string, value: string) => {
    const AssetStateTypeIdName = assetStateTypeData.find((a: { asset_state_type_id: string }) => a.asset_state_type_id === value).asset_state_type_name
    if (AssetSummaryData?.asset_state !== AssetStateTypeIdName) {
      setIsStateChanged(true)
    } else {
      setIsStateChanged(false)
    }
    setSummaryState({ ...summaryState, state: AssetStateTypeIdName })
  }

  const handleInstallationDate = (val: any) => {
    setInstallationStateDate(val)
    setSummaryState({ ...summaryState, installDate: val })
  }

  const checkIfEditInfoValid = (): boolean => {
    return (JSON.stringify(oldintialState) === JSON.stringify(summaryState)) || ipError !== '' || portError !== ''
  }

  const validateFields = (): void => {
    if (summaryState.tag[0]?.name === undefined || summaryState.tag[0]?.name !== undefined) {
      if (summaryState.tag[0].name) {
        if (!isValidName(summaryState.tag[0].name)) {
          setReqError(true)
          return
        }
      }
      if (summaryState.ip.ip_address) {
        if (!isValidIp(summaryState.ip.ip_address)) {
          setIpError('Invalid IP')
          return
        }
      }
      if (summaryState.ip.port) {
        if (!isValidPort(summaryState.ip.port)) {
          setPortError('Invalid Port')
          return
        }
      }
      if (isDateChanged) {
        setDateChanged(true)
      }
      setshowEditSummaryModal(true)
    }
    else {
      setReqError(true)
    }
  }

  // istanbul ignore next
  const AlertStateUpdate = (): JSX.Element => {
    if (props.editSummary.isLoading) {
      return (
          <GenericModal title='Alert!!'>
            <div className='CircularprogressAmi'>
              <CircularProgress data-testid="circular-progress"/>
            </div>
          </GenericModal>
      )
    } else if (props.editSummary.httpStatus === 412) {
      return (
            <GenericModal width={'40%'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative="Cancel" LabelPositive="Confirm"
                onClickNegative={() => {
                  setShowAlertStateUpdate(false)
                }}
                onClose={() => { setShowAlertStateUpdate(false) }} title="Alert!!"
                onClickPositive={() => {
                  summaryState.confirmedStateUpdate = true
                  props.editAssetSummaryInfo(summaryState, assetId ?? '', auth.user?.profile.customer as string)
                  setStateSucessModal(true)
                }}>
                <div className='modal-content-message'>
                    {props.editSummary.message}
                </div>
          </GenericModal>
      )
    } else if (props.editSummary.httpStatus === 200) {
      return (
            <GenericModal data-testid="circular-progress" title="SuccessMessage" width={'fit-content'} height={'fit-content'} LabelPositive="Ok"
                onClose={() => {
                  setShowAlertStateUpdate(false)
                }}
                onClickPositive={() => {
                  props.editAssetSummaryInfo(summaryState, assetId ?? '', auth.user?.profile.customer as string)
                  setShowAlertStateUpdate(false)
                  setStateSucessModal(true)
                }}>
                <div style={{ paddingTop: 20 }}>
                    {props.editSummary.message}
                </div>
          </GenericModal>
      )
    } else if (props.editSummary.httpStatus === 401) {
      return (
                <GenericModal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowAlertStateUpdate(false) }}
                    onClose={() => { setShowAlertStateUpdate(false) }} title="Alert!!">
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </GenericModal>
      )
    } else if (props.editSummary.httpStatus === 403) {
      return (
                <GenericModal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowAlertStateUpdate(false) }}
                    onClose={() => { setShowAlertStateUpdate(false) }} title="Alert!!">
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </GenericModal>
      )
    } else {
      return (
        <GenericModal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setShowAlertStateUpdate(false) }}
          onClose={() => { setShowAlertStateUpdate(false) }} title="Alert!!"
          onClickPositive={() => {
            summaryState.confirmedStateUpdate = true
            props.editAssetSummaryInfo(summaryState, assetId ?? '', auth.user?.profile.customer as string)
            setStateSucessModal(true)
          }}>
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} customError={props.editSummary.message}/></div>
        </GenericModal>
      )
    }
  }

  return (
    <>
      <div role='presentation' data-testid='breadcrumbs' style={{ width: '100%' }} >
        <Breadcrumbs aria-label='breadcrumb' sx={{ color: '#f0f0f0', fontSize: '12px', marginBottom: '14px', fontFamily: 'Honeywell Sans Web', marginTop: '12px' }}>
          <Link color='inherit' className='breadcrumbAssetMgmt' style={{ textDecoration: 'none', fontSize: '.875rem' }} data-testid='device-management-breadcrumb' onClick={() => { navigate('/isense') }} >
            {EditAssetSummaryStrings.AMI_Operations_Hub}
          </Link>
          <Link data-testid='asset-list' style={{ cursor: 'pointer', color: '#F0F0F0', textDecoration: 'none', fontSize: '.875rem' }} color='inherit' onClick={() => { navigate('/asset-list') }}>
            {EditAssetSummaryStrings.Asset_list}
          </Link>
          <Link data-testid='asset-serial' style={{ cursor: 'pointer', color: '#F0F0F0', textDecoration: 'none', fontSize: '.875rem' }} color='inherit' onClick={() => { navigate('/devicedetails') }}>
            {AssetSummaryData?.comm_mod_serial}
          </Link>
          <Link data-testid='asset-summary' style={{ cursor: 'pointer', color: '#F0F0F0', textDecoration: 'none', fontSize: '.875rem' }} color='inherit' onClick={() => { navigate('/edit-asset-summary') }}>
            {EditAssetSummaryStrings.Edit_Asset_summary}
          </Link>
        </Breadcrumbs>
      </div>
      <div className='topUtilityBar' style={{ marginBottom: '30px', marginRight: '0px' }}>
        <BackButton dataTestId='back-arrow' onclick={() => { navigate('/devicedetails') }} />
        <span id='componentTitle' data-testid='asset-Summary-title' >{EditAssetSummaryStrings.Edit_Asset_summary}</span>
      </div>
      <div className='edit-container' style={{
        backgroundColor: '#303030',
        border: '1px solid #000',
        margin: '20px 10px',
        padding: '20px',
        borderRadius: '4px'
      }}>
        <Grid container style={{ paddingTop: '20px' }}>
          <Grid container>
            <h4 data-testid='box-heading' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.title}</h4>
          </Grid>
          <span style={assetValuesStyles}></span>
          <Grid xs={12} sm={2}>
            <div
              style={{
                padding: '10px',
                backgroundColor: 'white',
                height: 'fit-content',
                borderRadius: '10px',
                width: 'fit-content',
                textAlign: 'center'
              }}
            >
              <img
                src={getDeviceImage(AssetSummaryData?.device_type)}
                style={{ width: '100px', height: 'auto' }}
              />
            </div>
          </Grid>
          <Grid container xs={12} sm={10}>
            <Grid container className='assetSummaryRow'>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Asset_Type}</Grid>
                <Grid className='assetSummaryListContent'>
                  {AssetSummaryData?.device_type}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.State}</Grid>
                  <Grid className='assetSummaryListContent'>
                    <GenericDropdown dataTestId='category' allowInput={true} width={'170'} options={AssetStateTypeOptions} value={getDropdownValue()} onChange={(e) => handleChooseState('assetStateType', e.target.value)} label={''} disabled={AssetSummaryData?.asset_state === 'Removed'}/>
                  </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                  <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Year_of_Certification}</Grid>
                  <Grid className='assetSummaryListContent'>None</Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Installation_Date}</Grid>
                <Grid className='assetSummaryListContent'>
                  <CustomLocalizationProviderWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <WhiteDatePicker
                          slotProps={{ textField: { fullWidth: true }, field: { readOnly: true } }}
                          disableFuture
                          value={summaryState.installDate}
                          onAccept={(val: any) => { handleInstallationDate(dayjs(val))
                            setDateChanged(true) }}
                        />
                      </LocalizationProvider>
                    </CustomLocalizationProviderWrapper>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className='assetSummaryRow'>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.ClipOn_Serial_Number}</Grid>
                <Grid className='assetSummaryListContent'>{AssetSummaryData?.comm_mod_serial}</Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Firmware_Version}</Grid>
                <Grid className='assetSummaryListContent'>{AssetSummaryData?.firmware_version}</Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Batch}</Grid>
                <Grid className='assetSummaryListContent'>
                  None
                </Grid>
              </Grid>
            </Grid>
            <Grid container className='assetSummaryRow'>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Meter_Date_of_Expiry}</Grid>
                <Grid className='assetSummaryListContent'>None</Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Remaining_Battery_Percentage}</Grid>
                <Grid className='assetSummaryListContent'>{(AssetSummaryData?.rem_bat_percent != null) ? AssetSummaryData?.rem_bat_percent?.toString() : 'Not Available'}</Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.IP_Port}</Grid>
                <Grid container style={{ color: '#64C3FF' }}>
                  <Grid item xs={12} sm={5} style={{
                    margin: '10px 10px 0px 0px'
                  }}>
                    <TextField id='outlined-basic' label='' size='small' variant='outlined' onChange={handleIpChange} value={ip}
                      sx={{
                        input: {
                          color: '#fff',
                          '&::placeholder': {
                            opacity: 1
                          }
                        },
                        label: { color: '#fff', fontSize: '12px' },
                        backgroundColor: '#645e5e'
                      }}
                    />
                    {ipError && summaryState?.ip.ip_address !== '' && !isValidIp(summaryState?.ip.ip_address) && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>Invalid IP Address</FormHelperText>}
                  </Grid>
                  <Grid item xs={12} sm={5} style={{
                    margin: '10px 10px 0px 0px'
                  }}>
                    <TextField id='outlined-basic' inputProps={{ maxLength: 5 }} label='' size='small' variant='outlined' onChange={handlePortChange} value={port}
                      sx={{
                        input: {
                          color: '#fff',
                          '&::placeholder': {
                            opacity: 1
                          }
                        },
                        label: { color: '#fff', fontSize: '12px' },
                        backgroundColor: '#645e5e'
                      }} />
                    {portError && summaryState?.ip.port !== '' && !isValidPort(summaryState?.ip.ip_address) && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>Invalid Port</FormHelperText>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className='assetSummaryRow'>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Meter_Serial_Number}</Grid>
                <Grid className='assetSummaryListContent'>
                  {AssetSummaryData?.serial_number}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Meter_Date_of_Shipment}</Grid>
                <Grid className='assetSummaryListContent'>None</Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid className='assetSummaryListHead' style={{ fontWeight: 'bold' }}>{EditAssetSummaryStrings.Tag}</Grid>
                <Grid className='assetSummaryListContent'>
                  <div className='tag-Update'>
                    <TextField
                      style={{
                        color: '#fff',
                        marginTop: '10px',
                        width: '100%',
                        backgroundColor: '#645e5e'
                      }}
                      autoComplete='off'
                      size='small'
                      id='outlined-textarea'
                      placeholder='Search Tag'
                      value={summaryState.tag[0].name}
                      inputProps={{ maxLength: 20 }}
                      onChange={(e: any) => handleSelecttags(e)}
                      sx={{
                        input: {
                          color: '#fff',
                          '&::placeholder': {
                            opacity: 1
                          }
                        },
                        label: { color: '#fff', fontSize: '12px' }
                      }}
                    />
                    {DisplayTag && AllTagsData?.length > 0 && !AllTagsDataLoading && (
                      <div ref={wrapperRef} className='list'>
                        {AllTagsData.map((item: any, index: any) => (
                          <div
                            key={item.id}
                            onClick={() => {
                              setSummaryState({ ...summaryState, tag: [{ name: item.name }] })
                              setDisplayTag(false)
                            }}
                            className='list-item'
                          >
                            {item.name}
                          </div>
                        ))}
                      </div>
                    )}
                    {reqError && summaryState.tag[0].name !== '' && !isValidName(summaryState?.tag[0]?.name) && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>Invalid Tag Name</FormHelperText>}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                paddingTop: '20px',
                paddingBottom: '20px'
              }}
              container
            >
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Modal className='Edit-AssetSummary-Modal' open={showEditSummaryModal} onClose={() => { closeAddModal() }}>
        <Box sx={modalStyle}>
          <div className='title'>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              {EditAssetSummaryStrings.Update_Asset_Summary}
            </Typography>
            <CloseIcon data-testid='Close-Button-Model'
              style={{ cursor: 'pointer' }}
              onClick={() => { closeAddModal() }}
            />
          </div>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {EditAssetSummaryStrings.Confirm_message} {AssetSummaryData?.comm_mod_serial}?
          </Typography>
          <div className='add-modal-button-container'>
            <Button data-testid='cancel-edit-summary' className='can-btn-asset'
              onClick={() => { closeAddModal() }} >
              Cancel
            </Button>
            <Button data-testid='edit-asset' className='btn-asset'
              onClick={handleConfirmUpdate} >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
      {tagUpdateHttpStatus && tagUpdateHttpStatus !== null
        ? <div>
          <Modal className='state-update-modal' open={StateSucessModal} onClose={() => { closeStateChangeModal() }}>
            <Box sx={modalStyle}>
              <div className='title'>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={10}>
                    <Typography id='modal-modal-title' variant='h6' component='h2'>
                      {tagUpdateHttpStatus !== 412 ? 'Asset Summary Status' : 'Alert!!'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <CloseIcon style={{ cursor: 'pointer' }} data-testid='close-button' onClick={() => { closeStateChangeModal() }} />
                  </Grid>
                </Grid>
              </div>
              <div className='state-div'>
                <h5>{tagUpdateHttpStatus === 200 ? EditAssetSummaryStrings.SucessResponce : tagUpdateHttpStatus === 400 ? EditAssetSummaryStrings.BadRequest : tagUpdateHttpStatus === 404 ? EditAssetSummaryStrings.NotFound : tagUpdateHttpStatus === 412 ? props.editSummary.message : EditAssetSummaryStrings.BadRequest}</h5>
              </div>
              {
                tagUpdateHttpStatus !== 412
                  ? <div className='add-modal-button-container' style={{ float: 'right' }}>
                  <Button data-testid='cancel-btn-id' className='can-btn-asset'
                    onClick={() => { closeStateChangeModal() }} >
                    Ok
                  </Button>
              </div>
                  : <div className='add-modal-button-container' style={{ float: 'right' }}>
                <Button data-testid='cancel-btn-id' className='can-btn-asset'
                  onClick={() => { setStateSucessModal(false) }} >
                  Cancel
                </Button>
                <Button data-testid='cancel-btn-id' className='can-btn-asset'
                  onClick={() => {
                    summaryState.confirmedStateUpdate = true
                    props.editAssetSummaryInfo(summaryState, assetId ?? '', auth.user?.profile.customer as string)
                    setStateSucessModal(true)
                  }} >
                  Confirm
                </Button>
              </div>
              }
            </Box>
          </Modal>
        </div>
        : ''
      }
      {
        showAlertStateUpdate
          ? AlertStateUpdate()
          : <></>
      }
      <div className='state-button-container state-update'>
        <Button dataTestId='cancel-btn-id' className='can-btn-asset' type='borderless'
          onClick={() => { navigate('/devicedetails') }} >
          Cancel
        </Button>
        <Button dataTestId='next-btn-select' className='update-btn' primaryColor={checkIfEditInfoValid() && !isDateChanged ? '#B0B0B0' : undefined}
          disabled={checkIfEditInfoValid() && !isDateChanged}
          onClick={() => { validateFields() }}>
          Update
        </Button>
      </div>
    </>
  )
}

interface DispatchToProps {
  editAssetSummaryInfo: (editSummaryParamter: editSummaryReq, assetId: string, tenantId: string) => void
  getAllTagsByIDInfo: (search: string) => void
  updateAssetStateInfo: (assetStateParamter: assetStateReq, tenantId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  editAssetSummaryInfo: (editSummaryParamter: editSummaryReq, assetId: string, tenantId: string) => dispatch(editAssetSummaryInfo(editSummaryParamter, assetId, tenantId)),
  getAllTagsByIDInfo: (search: string) => dispatch(getAllTagsByIDInfo(search)),
  updateAssetStateInfo: (assetStateParamter: assetStateReq, tenantId: string) => dispatch(updateAssetStateInfo(assetStateParamter, tenantId))
})

interface StateToProps {
  editSummary: EditAssetSummaryInfo
  updateAssetStateData: UpdateAssetStateInfo
}

const mapStateToProps = (state: RootState): StateToProps => ({
  editSummary: state.EditAssetSummaryInfoSlice,
  updateAssetStateData: state.updateAssetStateData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditAssetSummary)
