import * as React from 'react'
import { CircularProgress } from '@mui/material'
import Pagination from '../../../components/TablePagination'
import { type SnackbarOrigin } from '@mui/material/Snackbar'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import LanguageStrings from '../../../i18n/locales'
import { Button } from '../../../components/Button'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import {
  fetchAssetList,
  postAssetsForConfiguration,
  fetchAssetListBySearch
} from '../redux/actionCreator'
import { type AssetListState } from '../redux/unAssignedAssetsListSlice'
import { type Asset } from '../services/unAssignedAssetListService'
import { useEffect } from 'react'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type AssociateAssetsRequest } from '../services/associateAssetService'
import { type AssociateAssetState } from '../redux/associateAssetsSlice'
import Modal from '../../../components/Modal'
import SearchBox from '../../../components/SearchBox'

export interface State extends SnackbarOrigin {
  open: boolean
}

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  dataList: Asset[]
}
interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const TableStrings = LanguageStrings().AMIDataCollectionStrings.TableComponent
const AssignAssetListStrings =
  LanguageStrings().AssetConfigurationStrings.components.AssignAssetList
const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: TableStrings.ASSET_NAME
  },
  {
    id: 'serial_number',
    numeric: false,
    disablePadding: false,
    label: TableStrings.SerialNumber
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: TableStrings.ASSETID
  },
  {
    id: 'firmware_version',
    numeric: false,
    disablePadding: false,
    label: 'Firmware Version'
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'State'
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    label: 'Tag'
  }
]

function EnhancedTableHead (props: EnhancedTableProps): any {
  const { onSelectAllClick, numSelected, rowCount, dataList } = props
  return (
    dataList !== null &&
    dataList !== undefined && (
      <>
        <TableHead data-testid="table-header">
          <TableRow>
            <StyledTableCell padding="checkbox">
              <Checkbox
                sx={{ color: '#909090' }}
                data-testid="device-table-header-checkbox"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'Select all Assets',
                  id: 'select-all-items'
                }}
              />
            </StyledTableCell>
            {headCells.map((headCell: HeadCell) => (
              <StyledTableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    )
  )
}

const style = {
  width: '100%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

function AssignAssetList (props: AllProps): JSX.Element {
  const rowsPerPage = 10
  const sortdir = 'desc'
  const sortfield = 'name'

  const [assets, setAssets] = React.useState<Asset[]>([])
  const [selected, setSelected] = React.useState<string[]>([])
  const [showConfirmation, setShowConfirmation] =
    React.useState<boolean>(false)
  const [showAssociationResponse, setShowAssociationResponse] =
    React.useState<boolean>(false)
  const [page, setPage] = React.useState(1)
  const [searchValue, setSearchValue] = React.useState<string>('')

  useEffect(() => {
    handlefetchAssetList()
  }, [page])

  useEffect(() => {
    if (
      props.assetList.assetList !== null &&
      props.assetList.assetList !== undefined
    ) {
      setAssets(props.assetList.assetList)
    }
  }, [props.assetList.assetList])

  const handlefetchAssetList = (): void => {
    // istanbul ignore else
    if (page > 0) {
      props.fetchAssetList(
        props.assetTypeId,
        props.templateId,
        page - 1,
        rowsPerPage,
        sortdir,
        sortfield
      )
    }
  }

  const handleSearch = (): void => {
    if (page >= 1) {
      setPage(1)
      props.fetchAssetListBySearch(
        props.assetTypeId,
        props.templateId,
        page - 1,
        rowsPerPage,
        searchValue,
        sortdir,
        sortfield
      )
    }
  }

  const handleRowClick = (id: string): void => {
    const isIdSelected = selected.includes(id)
    // istanbul ignore else
    if (!isIdSelected) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((selectedId) => selectedId !== id))
    }
  }

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentPageAssets = assets.map((n) => n.id)
    if (event.target.checked) {
      const newSelected: string[] = []
      currentPageAssets.forEach((id) => {
        if (!selected.includes(id)) {
          newSelected.push(id)
        }
      })
      setSelected([...selected, ...newSelected])
    } else {
      const newSelected: string[] = []
      // istanbul ignore next
      selected.forEach((id) => {
        if (!currentPageAssets.includes(id)) {
          newSelected.push(id)
        }
      })
      setSelected(newSelected)
    }
  }

  const triggerAssign = (): void => {
    const associateAssets: AssociateAssetsRequest = {
      template_id: props.templateId,
      asset_type_id: props.assetTypeId,
      assets: selected
    }
    props.postAssetsForConfiguration(associateAssets)
  }

  const handleAssign = (): void => {
    setShowConfirmation(false)
    setShowAssociationResponse(true)
    triggerAssign()
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))
  const isSelected = (id: string): boolean => selected.includes(id)

  const handleCreateConfigurationWithFailure = (): JSX.Element => {
    const handleSuccessClickPositive = (): void => {
      setShowAssociationResponse(false)
      props.closeAssetAssignWindow()
      props.handleFetchConfigList()
    }

    const handleRetry = (): void => {
      triggerAssign()
    }

    if (props.associateAssets.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title="Successfully Assigned"
          LabelPositive="Ok"
          onClickPositive={handleSuccessClickPositive}
          onClose={handleSuccessClickPositive}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {
              AssignAssetListStrings.Asset_configuration_has_been_assigned_successfully
            }
          </p>
        </Modal>
      )
    } else if (props.associateAssets.isLoading) {
      return (
        <Modal title="Assign Asset Configuration">
          <div
            className="CircularprogressAmi"
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.associateAssets.httpStatus === 401) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => {
            setShowAssociationResponse(false)
          }}
          onClose={() => {
            setShowAssociationResponse(false)
          }}
          title="Assign Asset Configuration"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideModal'}
              errorMessage="Unauthorized"
            />
          </div>
        </Modal>
      )
    } else if (props.associateAssets.httpStatus === 403) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => {
            setShowAssociationResponse(false)
          }}
          onClose={() => {
            setShowAssociationResponse(false)
          }}
          title="Assign Asset Configuration"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideModal'}
              errorMessage="accessForbidden"
            />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          LabelPositive="Retry"
          onClickNegative={() => {
            setShowAssociationResponse(false)
          }}
          onClickPositive={handleRetry}
          onClose={() => {
            setShowAssociationResponse(false)
          }}
          title="Assign Asset Configuration"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideModal'}
              errorMessage="cannotAssign"
            />
          </div>
        </Modal>
      )
    }
  }

  const handleAssetTable = (): JSX.Element => {
    if (
      assets !== null &&
      assets !== undefined &&
      props.assetList.httpStatus === 200 &&
      assets.length > 0
    ) {
      return (
        <>
              <TableContainer className="device-table">
                <Table
                  sx={{ minWidth: 750, color: '#f0f0f0' }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <EnhancedTableHead
                    numSelected={
                      selected.filter((id) =>
                        assets.map((asset: Asset) => asset.id).includes(id)
                      ).length
                    }
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={assets.length}
                    dataList={assets}
                  />
                  <TableBody>
                    {assets?.map((row: Asset, index: number) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          aria-label="devices-list-row"
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              sx={{ color: '#909090' }}
                              data-testid={`row-checkbox-${index}`}
                              checked={isItemSelected}
                              onChange={() => {
                                handleRowClick(row.id)
                              }}
                              inputProps={{
                                'aria-labelledby': labelId,
                                'aria-label': 'test-checkbox',
                                id: `select-item-${index}`
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            aria-label="device-name"
                            data-testid="device-name"
                            sx={{ color: '#64C3FF !important' }}
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell>{row.serial_number}</StyledTableCell>
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>
                            {row.firmware_version}
                          </StyledTableCell>
                          <StyledTableCell>{row.state}</StyledTableCell>
                          <StyledTableCell>{row.tag}</StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={props.assetList.assetCount}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                siblingCount={1}
                boundaryCount={1}
              />
              <div
                className="assign-list-footer"
                style={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row-reverse'
                }}
              >
                <Button
                  dataTestId="assign-btn-from-window"
                  disabled={selected.length === 0}
                  onClick={() => {
                    setShowConfirmation(true)
                  }}
                  sx={{ float: 'right' }}
                >
                  {AssignAssetListStrings.Assign}
                </Button>
              </div>
          {showConfirmation && (
            <Modal
              buttonTypeNegative="hollow"
              title="Assign Asset Configuration"
              LabelNegative="Cancel"
              LabelPositive="Assign"
              positiveDisabled={selected.length === 0}
              onClickPositive={handleAssign}
              onClose={() => {
                setShowConfirmation(false)
              }}
              onClickNegative={() => {
                setShowConfirmation(false)
              }}
            >
              <p style={{ paddingTop: '1.2em' }}>
                {
                  AssignAssetListStrings.Do_you_want_to_assign_asset_configuration_to_the_selected_devices
                }
              </p>
            </Modal>
          )}
          {showAssociationResponse && handleCreateConfigurationWithFailure()}
        </>
      )
    } else if (props.assetList.isLoading) {
      return (
        <div
          className="CircularprogressAmi"
          style={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress />
        </div>
      )
    } else if (props.assetList.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (props.assetList.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else if (
      props.assetList.assetList !== null &&
      props.assetList.assetList !== undefined &&
      props.assetList.assetList.length === 0
    ) {
      return (
        <div className="authError">
          <AuthError errorMessage="NoDataPresent" />
        </div>
      )
    } else if (
      props.assetList.assetList === null &&
      props.assetList.loadingError ===
        'Invalid input-Assets not found for deviceTypeId'
    ) {
      return (
        <div className="authError">
          <AuthError errorMessage="NoDataPresent" />
        </div>
      )
    } else {
      return (
        <div className="authError">
          <AuthError errorMessage="cannotFetch" retry={handlefetchAssetList} />
        </div>
      )
    }
  }

  return (
    <Box sx={style} className="devices">
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#272727',
          boxShadow: 'unset',
          color: '#F0F0F0',
          marginBottom: '0',
          minHeight: '200px'
        }}
      >
        <SearchBox immediate={true}
          debounce={true}
          placeholder='Search'
          sx={{ width: '300px', marginLeft: '0px' }}
          emptyEnterFallback={() => {
            handlefetchAssetList()
          }}
          searchVal={searchValue} setSearchVal={setSearchValue}
          performAction={() => {
            handleSearch()
          }}
        />
        {handleAssetTable()}
      </Paper>
    </Box>
  )
}

interface DispatchToProps {
  fetchAssetList: (
    assetTypeId: string,
    templateId: string,
    page: number,
    size: number,
    sortdir: string,
    sortfield: string
  ) => void
  postAssetsForConfiguration: (associateAssets: AssociateAssetsRequest) => void
  fetchAssetListBySearch: (
    assetTypeId: string,
    templateId: string,
    page: number,
    size: number,
    parameter: string,
    sortdir: string,
    sortfield: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetList: (
    assetTypeId: string,
    templateId: string,
    page: number,
    size: number,
    sortdir: string,
    sortfield: string
  ) => dispatch(fetchAssetList(assetTypeId, templateId, page, size, sortdir, sortfield)),
  postAssetsForConfiguration: (associateAssets: AssociateAssetsRequest) =>
    dispatch(postAssetsForConfiguration(associateAssets)),
  fetchAssetListBySearch: (
    assetTypeId: string,
    templateId: string,
    page: number,
    size: number,
    parameter: string,
    sortdir: string,
    sortfield: string
  ) => dispatch(fetchAssetListBySearch(assetTypeId, templateId, page, size, parameter, sortdir, sortfield))
})

interface StateToProps {
  assetList: AssetListState
  associateAssets: AssociateAssetState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetList: state.assetsByAssetType,
  associateAssets: state.associateAssets
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
interface PropsFromParent {
  assetTypeId: string
  templateId: string
  closeAssetAssignWindow: () => void
  handleFetchConfigList: () => void
}
type AllProps = PropsFromRedux & PropsFromParent

export default connector(AssignAssetList)
