import React, { useEffect } from 'react'
import BackButton from '../../../components/BackButton'
import { useNavigate } from 'react-router-dom'
import CreateReportForm from './createNewReportTemplateComponents/CreateReportForm'
import ModifyReportContentForm from './createNewReportTemplateComponents/ModifyReportContentForm'
import { Button } from '../../../components/Button'
import { type Option } from '../../../components/GenXDropdown'
import { type CreateColumnContent, type CreateReportData } from '../types'
import PreviewModal from './createNewReportTemplateComponents/PreviewModal'
import Modal from '../../../components/Modal'
import { type RootState } from '../../../store'
import { type ConnectedProps, connect } from 'react-redux'
import { type CreateReportTemplateState } from '../redux/createReportTemplateSlice'
import { createReportTemplate, fetchReportContentColumns } from '../redux/actionCreators'
import { CircularProgress } from '@mui/material'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { isValidText } from '../../../utils/validator'
import LanguageStrings from '../../../i18n/locales'
import { type ReportContentColumnState } from '../redux/reportContentColumnSlice'

const CreateNewReportTemplateStrings = LanguageStrings().DataPublisherStrings.components.CreateNewReportTemplate

const CreateNewReportTemplate = (props: PropsFromRedux): JSX.Element => {
  const [reportName, setReportName] = React.useState('')
  const [reportTypes] = React.useState<Option[]>([{ label: 'CSV', value: '.csv' }, { value: '.cmep', label: 'CMEP' }])
  const [report, setReport] = React.useState<Option | null>(null)
  const [nameErrorInit, setNameErrorInit] = React.useState(false)
  const [reportContent, setReportContent] = React.useState<CreateColumnContent[]>([{ columnHeaderName: 'Mechanical Meter Serial No', columnDisplayName: 'Mechanical Meter Serial No', columnName: 'serial_number', columnOrder: 0, columnId: '6bcd01e3-cb97-40cf-94fa-7ae258edcbfc' }])
  const [showPrieview, setShowPreview] = React.useState(false)
  const [showCreateTemplateStatus, setShowCreateTemplateStatus] = React.useState(false)
  const [showSaveConfirmation, setShowSaveConfirmation] = React.useState(false)
  const [error, setError] = React.useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    props.fetchReportContentColumns()
  }, [])

  const handleFetchFieldOptions = (): JSX.Element => {
    if (props.reportContentColumns.httpStatus === 200 && props.reportContentColumns !== undefined && props.reportContentColumns.reportContentColumn.length > 0) {
      return (
        <ModifyReportContentForm source='CreateNewReportTemplate' reportName={reportName} error={error} setError={setError} fieldOptions={props.reportContentColumns.reportContentColumn} width='calc(100vw - 86px - 2rem)' reportContent={reportContent} setReportContent={setReportContent} />
      )
    } else if (props.reportContentColumns.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (props.reportContentColumns.httpStatus === 401) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.reportContentColumns.httpStatus === 403) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="accessForbidden" /></div>
      )
    } else if (props.reportContentColumns.httpStatus === 200 && props.reportContentColumns.reportContentColumn.length === 0) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="noData" /></div>
      )
    } else {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="cannotFetch" retry={props.fetchReportContentColumns} /></div>
      )
    }
  }

  const isNameNotValid = (): boolean => {
    return (!isValidText(reportName) || (reportName.length > 60))
  }

  const checkIfTemplateValid = (): boolean => {
    return (!isNameNotValid() && report !== null && reportContent.length > 0 &&
      reportContent.every((item) => item.columnHeaderName !== '' && item.columnName !== '') &&
      reportContent.every((item) => !(!isValidText(item.columnHeaderName) || (item.columnHeaderName.length > 60)))
    )
  }

  const handleCreateReportTemplate = (): void => {
    if (checkIfTemplateValid() && report !== null) {
      const reportData: CreateReportData = {
        reportName,
        reportType: report.value,
        reportContent
      }
      props.createReportTemplate(reportData)
    }
  }

  const handleCreateReportTemplateWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.createReportTemplateState.httpStatus === 201) {
      return (
        <Modal buttonTypeNegative='hollow' title={CreateNewReportTemplateStrings.Successfully_Created} LabelPositive={CreateNewReportTemplateStrings.Ok} onClickPositive={() => { navigate('/datapub') }}
          onClose={() => { navigate('/datapub') }}>
          <p style={{ paddingTop: '1.2em' }}>{CreateNewReportTemplateStrings.Report_Template_has_been_created_successfully}</p>
        </Modal>
      )
    } else if (props.createReportTemplateState.isLoading) {
      return (
        <Modal title={CreateNewReportTemplateStrings.Creating_Report_Template}>
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.createReportTemplateState.httpStatus === 401) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={CreateNewReportTemplateStrings.Close}
                    onClickNegative={() => { setShowCreateTemplateStatus(false) }}
                    onClose={() => { setShowCreateTemplateStatus(false) }} title={CreateNewReportTemplateStrings.Creating_Report_Template}>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </Modal>
      )
    } else if (props.createReportTemplateState.httpStatus === 403) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={CreateNewReportTemplateStrings.Close}
                    onClickNegative={() => { setShowCreateTemplateStatus(false) }}
                    onClose={() => { setShowCreateTemplateStatus(false) }} title={CreateNewReportTemplateStrings.Creating_Report_Template}>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </Modal>
      )
    } else {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={CreateNewReportTemplateStrings.Close} LabelPositive={CreateNewReportTemplateStrings.Retry}
          onClickNegative={() => { setShowCreateTemplateStatus(false) }}
          onClose={() => { setShowCreateTemplateStatus(false) }} title={CreateNewReportTemplateStrings.Creating_Report_Template}
          onClickPositive={handleCreateReportTemplate}>
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} customError={CreateNewReportTemplateStrings.UNABLE_TO_CREATE_REPORT_TEMPLATE} /></div>
        </Modal>
      )
    }
  }

  return (
    <div>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/datapub') }} className='assetmgmtli'>{CreateNewReportTemplateStrings.Data_Publisher}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{CreateNewReportTemplateStrings.Create_New_Report_Template}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton/>
        <span id='componentTitle'>{CreateNewReportTemplateStrings.Create_New_Report_Template}</span>
      </div>
      <CreateReportForm
        reportName={reportName}
        setReportName={setReportName}
        reportTypes={reportTypes}
        report={report}
        setReport={setReport}
        nameErrorInit={nameErrorInit}
        setNameErrorInit={setNameErrorInit}
      />
      {
        handleFetchFieldOptions()
      }
      <div style={{ width: '100%', backgroundColor: '#272727', display: 'flex', marginTop: '20px', justifyContent: 'space-between', alignItems: 'center', padding: '6px 24px' }}>
        <span onClick={ () => { navigate('/datapub') }} style={{ color: '#64C3FF', cursor: 'pointer' }} data-testid='cancel-btn'>{CreateNewReportTemplateStrings.Cancel}</span>
        <div>
          {/* <Button type='hollow' dataTestId='preview-btn' onClick={() => { setShowPreview(true) }}>{CreateNewReportTemplateStrings.Preview}</Button> */}
          <Button dataTestId='save-btn' primaryColor={checkIfTemplateValid() ? undefined : '#B0B0B0'} disabled={!checkIfTemplateValid()} onClick={() => { setShowSaveConfirmation(true) }}>{CreateNewReportTemplateStrings.Save}</Button>
        </div>
      </div>
      {
        showPrieview && <PreviewModal reportName={reportName} reportContent={reportContent} setShowPreview={setShowPreview} />
      }
      {
        showSaveConfirmation && <Modal title={CreateNewReportTemplateStrings.Create_New_Report_Template} LabelNegative={CreateNewReportTemplateStrings.Cancel} LabelPositive={CreateNewReportTemplateStrings.Confirm}
          onClickNegative={() => {
            setShowSaveConfirmation(false)
          }}
          onClickPositive={() => {
            setShowSaveConfirmation(false)
            setShowCreateTemplateStatus(true)
            handleCreateReportTemplate()
          }}
          buttonTypeNegative='hollow'
        >
          <p style={{ paddingTop: '1.2em', paddingBottom: '1.2em' }}>{CreateNewReportTemplateStrings.Do_you_want_to_create_this_report_template}</p>
        </Modal>
      }
      {
        showCreateTemplateStatus && handleCreateReportTemplateWithFailure()
      }
    </div>
  )
}

interface DispatchToProps {
  createReportTemplate: (createReportTemplatePostRequest: CreateReportData) => void
  fetchReportContentColumns: () => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  createReportTemplate: (createReportTemplatePostRequest: CreateReportData) => dispatch(createReportTemplate(createReportTemplatePostRequest)),
  fetchReportContentColumns: () => dispatch(fetchReportContentColumns())
})

interface StateToProps {
  createReportTemplateState: CreateReportTemplateState
  reportContentColumns: ReportContentColumnState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  createReportTemplateState: state.createReportTemplateState,
  reportContentColumns: state.reportContentColumns
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(CreateNewReportTemplate)
